import React, { useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { getAppVersion } from "../redux/app/action";
import { useDispatch } from "react-redux";
import {
  UseAuth,
  UseIsAdmin,
  UseUserDetails,
  UseAccess,
  UseIsDemo,
  UseIsPrime,
} from "../hooks/auth";
import {
  UseCompanyLoading,
  UseSubscriptionLevel,
  UseCompanyUsersRefresh,
} from "../hooks/company";

import ProtectedRoute from "./ProtectedRoute";
import routes from "./routes";
import AppInstaller from "../AppInstaller";

import socket from "../configs/socket";
import socketConstants from "../constants/socket";

import MainDashboard from "../pages/Main/Main";
import UnauthorizedPage from "../pages/UnauthorizedPage";


const { SOCKET_EVENT } = socketConstants;

function AppRoutes() {
  const currentUser = UseUserDetails();
  const auth = UseAuth();
  const access = UseAccess();
  const isDemo = UseIsDemo();
  const isPrime = UseIsPrime();
  const isAdmin = UseIsAdmin();
  // const subscriptionLevel = UseSubscriptionLevel();
  const availableRoutes = isPrime
    ? [...routes]
    : routes.filter((route) => !route.isPrime);
  const publicRoutes = availableRoutes.filter((route) => route.isPublic);
  const authRoutes = availableRoutes.filter((route) => !route.isPublic);

  // Retrieve new data for every app load / refresh
  UseCompanyUsersRefresh();

  React.useEffect(() => {
    if (currentUser) {
      socket.emit(SOCKET_EVENT.NEW_USER_CONNECTED, currentUser);
    }
  }, [currentUser]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAppVersion());
  }, [dispatch]);

  // Stop the auto scroll to top while the page still rendering
  const { scrollX, scrollY } = window;
  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  return (
    <>
      {auth && <AppInstaller />}
      <BrowserRouter>
        <Routes>
          {publicRoutes.map(({ key, path, element: Element }) => (
            <Route key={key} path={path} element={<Element />} />
          ))}
          <Route
            element={
              <ProtectedRoute isAllowed={!!auth} redirectPath={`/login?url=${window.location.pathname}${window.location.search}`} />
            }
          >
            <Route path="/" element={<MainDashboard />}>
              {authRoutes.map(
                ({
                  key,
                  path,
                  element: Element,
                  isAdmin: adminOnlyPage,
                  isDemo: demoPage,
                  isFreemium: freemiumPage = false,
                }) => {
                  const forAdmin = !isAdmin && adminOnlyPage; // Restricted pages to admin only
                  const accessRequired =
                    (access && access[key] === false) // || // Restricted access for subscription level
                    // (!freemiumPage && subscriptionLevel === 0) || // Restricted access for freemium users
                    // (!demoPage && isDemo); // Restrict access for demo users to demo pages only

                  if (accessRequired || forAdmin) {
                    return (
                      <Route
                        key={`${key}-unauthorized`}
                        path={path}
                        element={
                          <UnauthorizedPage
                            upgradeRequired={false}
                            forAdmin={forAdmin}
                          />
                        }
                      />
                    );
                  }
                  return <Route key={key} path={path} element={<Element />} />;
                },
              )}
            </Route>
          </Route>
          <Route
            path="/healthcheck"
            element={<h3>Hey There!!! The App is Healthy</h3>}
          />
        </Routes>
      </BrowserRouter>

      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 5000,
        }}
      />
    </>
  );
}

export default AppRoutes;
