import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createCustomWorkspaceKpi } from "../../../../../redux/kpi/actions";
import { UseGroupKpis, UseMyKpis } from "../../../../../hooks/kpi";
import { formatNumber } from "../../../../../helpers/formatter";
import { getWebhookData } from "../../../../../api/webhook";
import { UseUserDetails } from "../../../../../hooks/auth";

import {
  Divider,
  Grid,
  useTheme,
  CircularProgress,
  Tooltip,
  Collapse,
  Switch,
  Checkbox
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "lodash";

import SoftSelect from "../../../../SoftSelect";
import SoftBox from "../../../../SoftBox";
import SoftTypography from "../../../../SoftTypography";
import SoftButton from "../../../../SoftButton";
import SoftInput from "../../../../SoftInput";
import { CustomAnchorMetricSchema } from "../../../../../form-validations";
import { EFFECT_OPTIONS } from "../../../form/effectOptions";

import { ReactComponent as RocketSvg } from "../../../../../img/customkpi-rocket.svg";
import { ReactComponent as DiceSvg } from "../../../../../img/customkpi-dice.svg";
import {
  KPI_DIRECTIONS,
  KPI_KINDS,
  KPI_TYPES,
} from "../../../../../constants/kpi";
import { FORM_OPTIONS_ANCHOR } from "../../../form/formOptions";
import {
  anchorPointTypes,
  calculationOptions,
} from "./utils/constants";

import KpiSubmissionFormBuilder from "../../../../FormBuilder/KpiSubmission";

import { useHorizontalScroller } from "../../../../../hooks/useHorizontalScroller";
import { UseSelectedWorkspace } from "../../../../../hooks/company";
import { width } from "@mui/system";

const AnchorMetricDialog = ({ metric, closeMetricDialog, openDialog }) => {
  const userDetails = UseUserDetails();
  const [processing, setProcessing] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [effectIdx, setEffectIdx] = useState(0);
  const [formIdx, setFormIdx] = useState(0);
  const [inputs, setInputs] = useState([]);
  const groupKpis = UseGroupKpis();
  const userKpis = UseMyKpis();
  const [kpis, setKpis] = useState([]);
  const [urlCopied, setUrlCopied] = useState(false);
  const [payloadResponse, setPayloadResponse] = useState(false);
  const [webhookResponse, setWebhookResponse] = useState(false);
  const [webhookOptions, setWebhookOptions] = useState([]);
  const [payloadOptions, setPayloadOptions] = useState([]);
  const [payloadData, setPayloadData] = useState([]);

  const handleCheckWebhookResponse = () => {
    setWebhookResponse(true);
  };

  const reloadWebhookResponse = async () => {
    try {
      const response = await getWebhookData({
        companyId: userDetails.companyId
      });
      const webhookDatas = response.data
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .map(datas => ({
        value: datas._id,
        label: 'Response : ' + new Date(datas.createdAt).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }).replace(',', '') + ' UTC'
      }));
      setPayloadData(response.data);
      setWebhookOptions(webhookDatas);
    } catch (error) {
      console.error('Error fetching webhook data:', error);
      setWebhookOptions([]);
      setPayloadOptions([]);
    }
  };

  useEffect(() => {
    const fetchWebhookData = async () => {
      try {
        const response = await getWebhookData({
          companyId : userDetails.companyId
        });
        const webhookDatas = response.data
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map(datas => ({
          value: datas._id,
          label: 'Response : ' + new Date(datas.createdAt).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }).replace(',', '') + ' UTC'
        }));
        setPayloadData(response.data);
        setWebhookOptions(webhookDatas);
      } catch (error) {
        console.error('Error fetching webhook data:', error);
        setWebhookOptions([]); 
        setPayloadOptions([]);
      }
    };

    if (userDetails?.companyId) {
      fetchWebhookData();
    }
  }, [userDetails?.companyId,]);


  useEffect(() => {
    if (payloadResponse && payloadData.length) {
      const payloadDatas = payloadData
      .filter(datas => datas._id === payloadResponse)
      .map(datas => {
        const paramEntries = Object.entries(datas.parameters);
        return paramEntries.map(([column, value]) => ({
          value: column,
          label: `${column}: ${value}`
        }));
      }).flat();
      setPayloadOptions(payloadDatas);
    }
  }, [payloadResponse, payloadData]);

  useEffect(() => {
    // Assign current workspace KPIs (groupKpis) as "active" KPIs; data is fetched on first load and workspace change
    if (kpis.length) {
      const ons = [];
      const all = [];
      kpis.forEach((kpi) => {
        let active = false;
        const idx = groupKpis?.findIndex((k) => k.kpiModelId === kpi._id);
        if (idx >= 0) {
          active = true;
          ons.push({ ...kpi, ...groupKpis[idx] });
          kpi = { ...kpi, ...groupKpis[idx] };
        }
        all.push({
          ...kpi,
          target: kpi.defaultTarget,
          kpiModelId: kpi._id,
          active,
        });
      });
    }
  }, [kpis, groupKpis]);


  const dataSourceOneOptions = [
    { label: "Active", options: [] },
    { label: "Drafts", options: [] }
  ];

  groupKpis
    .sort((a, b) => Number(!!b.isDraft) - Number(!!a.isDraft))
    .forEach((kpi) => {
      const option = {
        value: kpi._id,
        label: `${kpi.title} - ${formatNumber(userKpis?.month[kpi._id]?.amt ?? 0)}`
      };
      
      if (kpi.isDraft) {
        dataSourceOneOptions[1].options.push(option);
      } else {
        dataSourceOneOptions[0].options.push(option);
      }
    });

  const dataSourceTwoOptions = [
    { label: "Active", options: [] },
    { label: "Drafts", options: [] }
  ];

  groupKpis
    .sort((a, b) => Number(!!b.isDraft) - Number(!!a.isDraft))
    .forEach((kpi) => {
      const option = {
        value: kpi._id,
        label: `${kpi.title} - ${formatNumber(userKpis?.month[kpi._id]?.amt ?? 0)}`
      };
      
      if (kpi.isDraft) {
        dataSourceTwoOptions[1].options.push(option);
      } else {
        dataSourceTwoOptions[0].options.push(option);
      }
    });


  const { _id: groupId } = UseSelectedWorkspace();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const currentValidationSchema = CustomAnchorMetricSchema[activeStep];
  const validationOptions = {
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    defaultValues: {
      name: "",
      kind: KPI_KINDS[2], // "%"
      direction: 1,
      unit: 0,
      point: 0,
      anchorType: anchorPointTypes[0].value,
      target: 0,
      percentageAnchorValue: 0,
      restAnchorValue: 0,
      advanced: false,
      dataSourceOne: "",
      calculationMethod: "",
      dataSourceTwo: "",
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
    trigger,
    watch,
  } = useForm(validationOptions);
  const watchAnchorType = watch("anchorType");
  const watchAdvanced = watch("advanced");
  const watchWebhook = watch("webhook");

  const getDefaultValue = () => {
    return anchorPointTypes[0];
  };

  const {
    selectorName,
    addScrollListener,
    removeScrollListener,
    setContainerScrollXOverflow,
  } = useHorizontalScroller();

  const handleKindSelect = (idx) => {
    setValue("kind", KPI_KINDS[idx]);
  };

  useEffect(() => {
    handleKindSelect(KPI_KINDS.findIndex((item) => item === "%"));

    return () => {
      // TO-DO: Optimize event listener removal on component unmount
      removeScrollListener();
    };
  }, []);

  useEffect(() => {
    if (activeStep === 2 && !selectorName) {
      addScrollListener(".itemlist");
    }
  }, [activeStep, selectorName, addScrollListener]);

  const handleInputs = (values) => {
    // Update both react-hook-form inputs and state inputs;
    // Relying on react-hook-form inputs alone does not trigger re-render when values change
    if (values && values.length) {
      setValue("inputs", [...values]);
      setInputs([...values]);
    } else {
      setValue("inputs", []);
      setInputs([]);
    }
  };

  const handleFormSelect = (idx) => {
    setFormIdx(idx);
    handleInputs(FORM_OPTIONS_ANCHOR[idx].initValue);
  };
  
  const [isChecked, setIsChecked] = useState(false);

  const handleNext = debounce(async () => {
    const isStepCompleted = await trigger();

    if (isStepCompleted) {
      setActiveStep((prevActiveStep) => prevActiveStep + (isChecked ? 2 : 1));
    }
  }, 250);

  const handleBack = () => {
    if (activeStep === 2) {
      removeScrollListener();
    }

    setActiveStep((prevActiveStep) => prevActiveStep - (isChecked ? 2 : 1));
  };

  const handleEffectSelect = (idx) => {
    setEffectIdx(idx);
    setValue("direction", KPI_DIRECTIONS[idx]);
  };

  const handleClose = () => {
    setProcessing(false);
    setEffectIdx(0);
    setFormIdx(0);
    setActiveStep(0);
    clearErrors();
    reset();
    setInputs([]);
    closeMetricDialog();
  };

  const handleRegister = async (formData) => {
    setProcessing(true);

    const { inputs: rawInputs, type, ...data } = formData;
    let requiredInputs = [];

    if (rawInputs && rawInputs.length) {
      requiredInputs = rawInputs.map((el) => {
        // Extract only valid elements; it may have extra values from Form Builder
        const { type: inputType, value, desc, enabled } = el;
        
        // For notification settings, include both enabled state and email value
        if (inputType === 'notification_settings') {
          return {
            type: inputType,
            value: value || '',
            enabled: enabled || false,
            desc
          };
        }

        // For all other input types
        return {
          type: inputType,
          value: value.toLowerCase().replace(" ", "_"),
          desc,
        };
      });
    }

    let target = data?.target;
    let unit = data?.unit;
    const hasLink = requiredInputs.find((item) => item.type === "link");

    // Set the "%" target (a.k.a. "anchor") and unit value if anchorType is "percentage"
    // Divide them by 100 to send as "decimal percentage" to server (previous slider implementation)
    if (data?.kind === "%") {
      target = parseFloat(target) / 100;
      unit = parseFloat(data?.unit) / 100;
    }

    const kpiData = {
      ...data,
      groupId,
      metricType: KPI_TYPES[1],
      target: parseFloat(target),
      unit: parseFloat(unit),
      gap: true,
      kind: hasLink ? "#" : formData.kind,
      ...(requiredInputs.length && { requiredInputs }),
    };

    dispatch(createCustomWorkspaceKpi(kpiData));
    // Fake success notification that appears after a short delay (and not when data has been successfully processed)
    setTimeout(() => {
      openDialog();
    }, 1500);

    handleClose();
  };

  const getKindSymbolIndex = (anchorLabel) => {
    if (anchorLabel === anchorPointTypes[1].value) return 0;
    if (anchorLabel === anchorPointTypes[2].value) return 1;
    if (anchorLabel === anchorPointTypes[0].value) return 2;
  };

  return (
      <SoftBox id="metric-form">
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftBox sx={{ display: "flex", gap: "0.5rem" }}>
            <SoftBox>
              <SoftTypography
                variant="h4"
                sx={{ color: "#FFF", fontSize: "20px" }}
              >
                Create {metric.metricTitle}
              </SoftTypography>
              <SoftTypography
                variant="caption"
                sx={{ color: "#FFF", fontSize: "12px" }}
              >
                Set daily targets and track real-time performance against
                predefined goals
              </SoftTypography>
            </SoftBox>

            <SoftBox
              sx={{
                transform: "rotate(6.137deg)",
                alignSelf: "end",
                height: "max-content",
              }}
            >
              <RocketSvg />
            </SoftBox>
          </SoftBox>

          <SoftButton
            variant="outlined"
            color="white"
            onClick={closeMetricDialog}
            sx={{
              "& svg": { fontSize: "1.25rem !important" },
              borderRadius: "50%",
              minWidth: "max-content",
              minHeight: "max-content",
              padding: "0.25rem",
            }}
          >
            <CloseRoundedIcon />
          </SoftButton>
        </SoftBox>
        <SoftBox
          display="flex"
          justifyContent="center"
          marginY="1rem"
          alignItems="center"
          gap="0.5rem"
        >
          <SoftBox>
            <SoftBox
              height="7px"
              width="295px"
              sx={{
                background: "rgba(249, 245, 255, 0.10)",
                borderRadius: "3px",
                position: "relative",
              }}
            >
              <SoftBox
                height="7px"
                sx={{
                  background: "#F9FAFB",
                  borderRadius: "3px",
                  width: ["33%", "65%", "100%"][activeStep],
                  position: "absolute",
                }}
              />
            </SoftBox>
          </SoftBox>

          <SoftTypography variant="button" sx={{ color: "#CED4DA" }}>
            {activeStep === 2 && isChecked ? 2 : activeStep + 1}/{isChecked ? 2 : 3}
          </SoftTypography>
        </SoftBox>

        <Divider
          sx={{
            backgroundColor: "#FFF",
          }}
        />

        <form onSubmit={handleSubmit(handleRegister)}>
          <Grid container spacing={1} className="mt-2">
            {activeStep === 0 && (
              <>
                <Grid item mini={12} marginBottom={1}>
                  <SoftBox
                    sx={{
                      marginBottom: "0.25rem",
                    }}
                  >
                    <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                      Metric Name
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    size="large"
                    {...register("name")}
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                    sx={{
                      border: "1px solid rgba(233, 236, 239, 0.10)",
                      backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                      color: "#ffffffb3 !important",
                      "& .MuiInputBase-input::placeholder": {
                        color: "white !important",
                        opacity: "0.7",
                      },
                      "&.Mui-focused": {
                        borderColor: primaryColor,
                        boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                      },
                    }}
                  />
                </Grid>
                <Grid item mini={12}>
                  <SoftBox>
                    <SoftBox display="flex" alignItems="flex-start" gap={2}>
                      <SoftBox>
                        <Tooltip 
                          title={groupKpis && groupKpis?.filter(kpi => kpi.isDraft)?.length >= 5 ? "You've reached max limit of 5 draft metrics" : ""}
                        >
                          <span>
                            <Checkbox
                              {...register("isDraft", {
                                onChange: (e) => {
                                  setValue("isDraft", e.target.checked);
                                  setIsChecked(e.target.checked);
                                },
                              })}
                              disabled={groupKpis && groupKpis?.filter(kpi => kpi.isDraft)?.length >= 5}
                              error={!!errors?.isDraft}
                              helperText={errors?.isDraft?.message}
                            />
                          </span>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="column" gap={2}>
                        <SoftTypography
                          variant="h4"
                          sx={{
                            marginTop: "0.25rem",
                            color: "#CED4DA",
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          Save as a hidden/draft metrics (optional)
                        </SoftTypography>
                        <SoftTypography variant="p" sx={{ color: "#FFF", fontSize: "12px", fontWeight: 500, mb: 1, marginTop: "-0.5rem" }}>
                          Hidden or draft metrics are not displayed on the dashboard but can be used as data source for composite metrics
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
                <Grid
                  item
                  mini={12}
                  marginBottom={3}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    borderRadius: "0.5rem",
                    marginLeft: "8px",
                    padding: "0.75rem !important",
                  }}
                >
                  <SoftTypography
                    component="h5"
                    sx={{
                      textTransform: "none",
                      color: "#E9ECEF",
                      fontWeight: 500,
                      fontSize: "13px",
                    }}
                  >
                    Select Anchor Point
                  </SoftTypography>
                  <SoftTypography
                    component="p"
                    sx={{
                      textTransform: "none",
                      color: "#D0D5DD",
                      fontWeight: 400,
                      fontSize: "12px",
                    }}
                  >
                    This represents the threshold value at which your metric
                    will anchor, determining point gains or losses
                  </SoftTypography>
                  <SoftBox sx={{ marginTop: "15px" }}>
                    <SoftTypography
                      component="p"
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        fontWeight: 400,
                        fontSize: "13px",
                      }}
                    >
                      Enter Desired anchor point
                    </SoftTypography>
                    <SoftBox display="flex" gap="0.5rem" alignItems="center">
                      <SoftBox sx={{ width: "100%" }}>
                        <SoftInput
                          fullWidth
                          {...register("target")}
                          error={!!errors?.target}
                          helperText={errors?.target?.message}
                          sx={{
                            border: "1px solid rgba(233, 236, 239, 0.10)",
                            backgroundColor:
                              "rgba(255, 255, 255, 0.10) !important",
                            color: "#ffffffb3 !important",
                            "& .MuiInputBase-input::placeholder": {
                              color: "white !important",
                              opacity: "0.7",
                            },
                            "&.Mui-focused": {
                              borderColor: primaryColor,
                              boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                            },
                          }}
                        />
                      </SoftBox>
                      <SoftSelect
                        options={anchorPointTypes}
                        {...register("anchorType", { required: true })}
                        error={!!errors?.anchorType}
                        onChange={(option) => {
                          const kindIndex = getKindSymbolIndex(option.value);

                          setValue("anchorType", option.value);
                          handleKindSelect(kindIndex);
                        }}
                        defaultValue={getDefaultValue()}
                        styles={{
                          color: "white !important",
                          width: "150px !important",
                          height: "fit-content !important",
                          placeholder: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          input: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          singleValue: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                        }}
                      />
                    </SoftBox>
                    {watchAnchorType === "percentage" && (
                      <SoftTypography
                        component="p"
                        sx={{
                          color: "#77ED83",
                          fontWeight: 400,
                          fontSize: "13px",
                          marginBottom: "20px",
                        }}
                      >
                        Enter an anchor point from 0% - 100%
                      </SoftTypography>
                    )}
                    {watchAnchorType !== "percentage" && (
                      <SoftTypography
                        component="p"
                        sx={{
                          color: "#77ED83",
                          fontWeight: 400,
                          fontSize: "13px",
                          marginBottom: "20px",
                        }}
                      >
                        Enter an Anchor point of any value of your choice
                      </SoftTypography>
                    )}
                    <SoftTypography
                      component="p"
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        fontWeight: 400,
                        fontSize: "12px",
                      }}
                    >
                      Note: This value determines point gains or losses based on
                      your metric's performance relative to this anchor
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item mini={12} marginBottom={3}>
                  <SoftBox
                    sx={{
                      marginBottom: "0.25rem",
                    }}
                  >
                    <SoftBox
                      sx={{
                        marginBottom: "0.25rem",
                      }}
                    >
                      <SoftTypography
                        variant="button"
                        sx={{ color: "#E9ECEF" }}
                      >
                        Set Your Units{" "}
                        <Tooltip
                          title="Specify the measurement scale for your metric. Choose from suggested defaults or enter your own. For example, if you're tracking sales, units could be 'dollars' or 'items sold"
                          placement="top"
                        >
                          <InfoOutlined />
                        </Tooltip>
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftInput
                    size="large"
                    {...register("unit")}
                    error={!!errors?.unit}
                    helperText={errors?.unit?.message}
                    sx={{
                      border: "1px solid rgba(233, 236, 239, 0.10)",
                      backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                      color: "#ffffffb3 !important",
                      "& .MuiInputBase-input::placeholder": {
                        color: "white !important",
                        opacity: "0.7",
                      },
                      "&.Mui-focused": {
                        borderColor: primaryColor,
                        boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                      },
                    }}
                  />
                </Grid>
                <Grid item mini={12} marginBottom={3} sx={{ display: isChecked ? "none" : "block" }}>
                  <SoftBox
                    sx={{
                      marginBottom: "0.25rem",
                    }}
                  >
                    <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                      Set a Point Value{" "}
                      <Tooltip
                        title="Choose the point value for each unit of progress achieved. This determines the scoring system for your goals, helping you track and reward performance effectively"
                        placement="top"
                      >
                        <InfoOutlined />
                      </Tooltip>
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    size="large"
                    {...register("point")}
                    error={!!errors?.point}
                    helperText={errors?.point?.message}
                    onClick={() => {
                      const container = document.getElementById("metric-form");
                      container.parentElement.scrollTop =
                        container.parentElement.scrollHeight;
                    }}
                    sx={{
                      border: "1px solid rgba(233, 236, 239, 0.10)",
                      backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                      color: "#ffffffb3 !important",
                      "& .MuiInputBase-input::placeholder": {
                        color: "white !important",
                        opacity: "0.7",
                      },
                      "&.Mui-focused": {
                        borderColor: primaryColor,
                        boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                      },
                    }}
                  />
                </Grid>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Grid item mini={12} marginBottom={3}>
                  <SoftBox
                    sx={{
                      display: "flex",
                      gap: "0.25rem",
                      marginBottom: "0.25rem",
                    }}
                  >
                    <DiceSvg sx={{ color: "rgba(108, 117, 125, 1)" }} />
                    <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                      Effect Type
                    </SoftTypography>
                  </SoftBox>

                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "0.5rem",
                    }}
                    {...register("direction")}
                  >
                    {EFFECT_OPTIONS.map((effect, index) => (
                      <SoftButton
                        onClick={() => handleEffectSelect(index)}
                        sx={{
                          background: "rgba(255, 255, 255, 0.10)",
                          border:
                            effectIdx === index
                              ? `1px solid ${primaryColor}`
                              : "1px solid rgba(233, 236, 239, 0.10)",
                          borderRadius: "8px",
                          padding: "0.5rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "start",
                          boxShadow:
                            effectIdx === index
                              ? `0rem 0rem 0rem 0.1rem ${primaryColor}`
                              : "none",
                          "&:hover": {
                            borderColor: primaryColor,
                            background: "rgba(255, 255, 255, 0.10)",
                            boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                          },

                          "&:focus:not(:hover)": {
                            borderColor: primaryColor,
                            background: "rgba(255, 255, 255, 0.10)",
                            boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                          },
                        }}
                      >
                        <SoftBox
                          display="flex"
                          gap="0.15rem"
                          alignItems="center"
                          marginBottom="0.25rem"
                          width="100%"
                          color="#CED4DA"
                        >
                          {effect.icon}
                          <SoftTypography
                            variant="button"
                            sx={{
                              color: "#CED4DA",
                              fontSize: "15px",
                              fontWeight: 500,
                            }}
                          >
                            {effect.name}
                          </SoftTypography>
                        </SoftBox>
                        <SoftTypography
                          variant="caption"
                          sx={{
                            color: "#FFF",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginLeft: "0.25rem",
                          }}
                        >
                          {effect.desc}
                        </SoftTypography>
                      </SoftButton>
                    ))}
                  </SoftBox>
                  {errors?.direction?.message && (
                    <p className="text-danger text-xs font-semibold">
                      {errors.direction.message}
                    </p>
                  )}
                </Grid>

                <Grid item mini marginBottom={3}>
                  <SoftBox sx={{ lineHeight: "15px" }}>
                    <SoftTypography
                      variant="caption"
                      sx={{
                        fontWeight: 500,
                        fontSize: "12px",
                        color: "#D0D5DD",
                      }}
                    >
                      Select the effect type of your metric. Choose 'Positive'
                      if achieving a higher value for this metric is beneficial
                      to your goals, or 'Negative' if a lower value is
                      preferred.
                      <br />
                      Indicate the value type of your metric. Choose 'Monetary'
                      if your metric represents financial values
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              </>
            )}
            {activeStep === 2 && (
              <Grid item mini={12} marginBottom={3}>
                <SoftBox
                  className="shadow"
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    borderRadius: "0.5rem",
                    padding: "0.75rem !important",
                    marginBottom: "15px",
                  }}
                >
                  <SoftBox display="flex" alignItems="flex-start" gap={2}>
                    <SoftBox>
                      <Switch
                        {...register("advanced")}
                        error={!!errors?.advanced}
                        helperText={errors?.advanced?.message}
                        onChange={(e) => setValue("advanced", e.target.checked)}
                      />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column" gap={1}>
                      <SoftTypography
                        variant="h4"
                        sx={{
                          color: "#CED4DA",
                          fontSize: "15px",
                          fontWeight: 500,
                        }}
                      >
                        Advanced Settings/Composite setup
                      </SoftTypography>
                      <SoftTypography
                        variant="p"
                        sx={{
                          color: "#FFF",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        When toggling the advanced settings, you gain access to
                        configure composite metrics, allowing you to combine
                        multiple performance indicators into a comprehensive
                        measure
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <Collapse in={watchAdvanced}>
                    <Divider
                      sx={{
                        backgroundColor: "#FFF",
                      }}
                    />
                    <SoftBox display="flex" gap={1}>
                      <SoftSelect
                        options={dataSourceOneOptions}
                        {...register("dataSourceOne", { required: true })}
                        error={!!errors?.dataSourceOne}
                        helperText={errors?.dataSourceOne?.message}
                        onChange={(option) =>
                          setValue("dataSourceOne", option.value)
                        }
                        placeholder="Data Source 1"
                        styles={{
                          color: "white !important",
                          height: "fit-content !important",
                          placeholder: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          input: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          singleValue: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                        }}
                      />
                      <SoftSelect
                        options={calculationOptions}
                        {...register("calculationMethod", { required: true })}
                        error={!!errors?.calculationMethod}
                        helperText={errors?.calculationMethod?.message}
                        onChange={(option) =>
                          setValue("calculationMethod", option.value)
                        }
                        placeholder="Select Operator"
                        styles={{
                          color: "white !important",
                          height: "fit-content !important",
                          placeholder: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          input: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          singleValue: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                        }}
                      />
                      <SoftSelect
                        options={dataSourceTwoOptions}
                        {...register("dataSourceOne", { required: true })}
                        error={!!errors?.dataSourceTwo}
                        helperText={errors?.dataSourceTwo?.message}
                        onChange={(option) =>
                          setValue("dataSourceTwo", option.value)
                        }
                        placeholder="Data Source 2"
                        styles={{
                          color: "white !important",
                          height: "fit-content !important",
                          placeholder: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          input: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          singleValue: {
                            color: "white !important",
                            opacity: "0.7",
                          },
                        }}
                      />
                    </SoftBox>
                    <Divider
                      sx={{
                        backgroundColor: "#FFF",
                      }}
                    />
                    <SoftTypography
                      variant="p"
                      sx={{
                        color: "#FFF",
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      Need more flexibility? Easily incorporate an additional
                      data source for more comprehensive analysis and insights
                    </SoftTypography>
                  </Collapse>
                </SoftBox>
                <SoftBox
                  className="shadow"
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    borderRadius: "0.5rem",
                    padding: "0.75rem !important",
                    marginBottom: "15px",
                  }}
                >
                  <SoftBox display="flex" alignItems="flex-start" gap={2}>
                    <SoftBox>
                      <Switch
                        {...register("webhook")}
                        error={!!errors?.webhook}
                        helperText={errors?.webhook?.message}
                        onChange={(e) => setValue("webhook", e.target.checked)}
                      />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column" gap={2}>
                      <SoftTypography
                        variant="h4"
                        sx={{
                          marginTop: "0.25rem",
                          color: "#CED4DA",
                          fontSize: "15px",
                          fontWeight: 500,
                        }}
                      >
                        Webhook Setup
                      </SoftTypography>
                      <SoftTypography variant="p" sx={{ color: "#FFF", fontSize: "12px", fontWeight: 500, mb: 1, marginTop: "-0.5rem" }}>
                        Copy the URL and paste it into your webhook setup
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <Collapse in={watchWebhook}>
                  <Divider
                    sx={{
                      backgroundColor: "#FFF",
                    }}
                  />
                  <SoftBox sx={{ mb: 2 }}>
                    <SoftBox>
                      <SoftTypography variant="h4" sx={{ color: "#FFF", fontSize: "15px", fontWeight: 500, mb: 1 }}>
                        Game My Biz Webhook URL
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="row" alignItems="center" gap={2} sx={{ '& > *': { flex: 1 } }}>
                      <SoftInput
                        value={`https://api.gamemybiz.com/webhook?id=${userDetails.companyId}`}
                        disabled
                        sx={{
                          flex: 1,
                          color: "#FFF",
                          pointerEvents: "none",
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#FFF",
                            opacity: 0.7
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(255, 255, 255, 0.23)"
                          }
                        }}
                      />
                      <SoftButton variant="contained" color="primary" onClick={() => {
                        navigator.clipboard.writeText(`https://api.gamemybiz.com/webhook?id=${userDetails.companyId}`);
                        setUrlCopied(true);
                        setWebhookResponse(true);
                      }}>Copy URL</SoftButton>
                    </SoftBox>
                    {urlCopied && 
                      <>
                        <SoftBox display="flex" flexDirection="row" alignItems="center" gap={2} sx={{ '& > *': { flex: 1 }, mt: 3 }}>
                          {webhookResponse && (
                            <SoftSelect
                              options={[...webhookOptions]}
                              placeholder="Select Webhook Response"
                              onChange={(option) => {
                                setPayloadResponse(option.value);
                              }}
                              styles={{
                                color: "white !important",
                                height: "fit-content !important",
                                placeholder: {
                                  color: "white !important",
                                  opacity: "0.7",
                                },
                                input: {
                                  color: "white !important",
                                  opacity: "0.7",
                                },
                                singleValue: {
                                  color: "white !important",
                                  opacity: "0.7",
                                },
                              }}
                            />
                          )}
                          {webhookResponse &&
                            <SoftButton variant="contained" color="primary" width="100%" onClick={reloadWebhookResponse}>Reload Response</SoftButton>
                          }
                        </SoftBox>
                        {payloadResponse && (
                        <SoftBox display="flex" flexDirection="row" alignItems="center" gap={2} sx={{ '& > *': { flex: 1 }, mt: 3 }}>
                          <SoftSelect
                              {...register("filter")}
                              options={payloadOptions}
                              placeholder="Select Payload Filter"
                              onChange={(option) => {
                              setValue("filter", option.value);
                            }}
                            styles={{
                              color: "white !important",
                              height: "fit-content !important",
                              placeholder: {
                                color: "white !important",
                                opacity: "0.7",
                              },
                              input: {
                                color: "white !important",
                                opacity: "0.7",
                              },
                              singleValue: {
                                color: "white !important",
                                opacity: "0.7",
                              },
                            }}
                          />
                          <SoftSelect
                              {...register("filterAction")}
                              options={[
                                { value: 'add', label: 'Add' },
                                { value: 'skip', label: 'Skip' }
                              ]}
                              placeholder="Select Payload Filter Action"
                              onChange={(option) => {
                              setValue("filterAction", option.value);
                            }}
                            styles={{
                              color: "white !important",
                              height: "fit-content !important",
                              placeholder: {
                                color: "white !important",
                                opacity: "0.7",
                              },
                              input: {
                                color: "white !important",
                                opacity: "0.7",
                              },
                              singleValue: {
                                color: "white !important",
                                opacity: "0.7",
                              },
                            }}
                          />
                        </SoftBox>
                        )}
                      </>
                    }
                  </SoftBox>
                </Collapse>
                {!watchAdvanced &&
                <SoftBox
                  sx={{
                    display: "flex",
                    gap: "0.25rem",
                    marginBottom: "0.25rem",
                  }}
                >
                  <DiceSvg sx={{ color: "rgba(108, 117, 125, 1)" }} />
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Select Submission Type
                  </SoftTypography>
                </SoftBox>
                }
                {!watchAdvanced &&
                <SoftBox
                  className="itemlist"
                  sx={{
                    width: "100%",
                    overflowX: setContainerScrollXOverflow(),
                    "-webkit-overflow-scrolling": "touch",
                    "::-webkit-scrollbar": {
                      display: "none",
                      height: 0,
                      width: 0,
                    },
                  }}
                >
                  <SoftBox
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      width: "max-content",
                      gap: "10px",
                      overflowX: "auto",
                      overFlowY: "hidden",
                    }}
                  >
                    {FORM_OPTIONS_ANCHOR.map((formType, index) => (
                      <SoftButton
                        disabled={index === 2} // Disable email submission for now
                        onClick={() => handleFormSelect(index)}
                        sx={{
                          width: "250px !important",
                          background: "rgba(255, 255, 255, 0.10)",
                          border:
                            formIdx === index
                              ? `1px solid ${primaryColor}`
                              : "1px solid rgba(233, 236, 239, 0.10)",
                          borderRadius: "8px",
                          padding: "0.5rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          flex: "0 0 auto",
                          textAlign: "start",
                          margin: "4px",
                          boxShadow:
                            formIdx === index
                              ? `0rem 0rem 0rem 0.1rem ${primaryColor}`
                              : "none",
                          "&:hover": {
                            borderColor: primaryColor,
                            background: "rgba(255, 255, 255, 0.10)",
                            boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                          },

                          "&:focus:not(:hover)": {
                            borderColor: primaryColor,
                            background: "rgba(255, 255, 255, 0.10)",
                            boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                          },
                          "&:disabled": {
                            backgroundColor: "transparent !important",
                          },
                        }}
                      >
                        <SoftBox
                          display="flex"
                          gap="0.15rem"
                          alignItems="center"
                          marginBottom="0.25rem"
                          width="100%"
                        >
                          {formType.icon}
                          <SoftTypography
                            variant="button"
                            sx={{
                              color: "#CED4DA",
                              fontSize: "15px",
                              fontWeight: 500,
                            }}
                          >
                            {formType.name}
                          </SoftTypography>
                        </SoftBox>
                        <SoftTypography
                          variant="caption"
                          sx={{
                            color: "#FFF",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginLeft: "0.25rem",
                          }}
                        >
                          {formType.desc}
                        </SoftTypography>
                      </SoftButton>
                    ))}
                  </SoftBox>
                </SoftBox>
                }
                {/** KPI Submission Form Builder */}
                <SoftBox
                  borderRadius="lg"
                  className="w-full h-auto my-4 overflow-hidden p-4"
                  sx={{
                    background:
                      "linear-gradient(0deg, #414143, #414143), linear-gradient(0deg, rgba(108, 117, 125, 0.4), rgba(108, 117, 125, 0.4))",
                  }}
                >
                  <SoftTypography
                    variant="h5"
                    color="white"
                    // className="px-4 pt-4"
                  >
                    KPI Submission Form
                  </SoftTypography>
                  <Divider
                    sx={{
                      backgroundColor: "#FFF",
                    }}
                  />
                  {formIdx <= 3 && (
                    <>
                      {/* eslint-disable */}
                      <SoftInput
                        size="large"
                        placeholder={FORM_OPTIONS_ANCHOR[formIdx].placeholder}
                        value={inputs[0]?.value}
                        onChange={(e) => {
                          // Match input format to complex form builder so any approach can be validated in the same way
                          const formInputs = [
                            {
                              value: e.target.value,
                              type: FORM_OPTIONS_ANCHOR[formIdx].type,
                            },
                          ];
                          handleInputs(formInputs);
                        }}
                        // Display error from the 2 main submission types  (text, image) aside from form submission
                        error={
                          !Array.isArray(errors?.inputs)
                            ? !!errors?.inputs
                            : !!errors?.inputs[0].value
                        }
                        helperText={
                          !Array.isArray(errors?.inputs)
                            ? errors?.inputs?.message
                            : errors?.inputs[0].value?.message
                        }
                        sx={{
                          border: "1px solid rgba(233, 236, 239, 0.10)",
                          backgroundColor:
                            "rgba(255, 255, 255, 0.10) !important",
                          color: "#ffffffb3 !important",
                          "& .MuiInputBase-input::placeholder": {
                            color: "white !important",
                            opacity: "0.7",
                          },
                          "&.Mui-focused": {
                            borderColor: primaryColor,
                            boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                          },
                        }}
                      />
                    </>
                  )}
                  {formIdx === 4 && (
                    <KpiSubmissionFormBuilder
                      inputs={inputs}
                      updateInputs={(values) => handleInputs(values)}
                      errors={errors}
                    />
                  )}
                </SoftBox>
              </Grid>
            )}
          </Grid>

          <Divider
            sx={{
              backgroundColor: "#FFF",
            }}
          />

          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "0.75rem",
              margin: "1rem",
            }}
          >
            {activeStep === 0 && (
              <SoftButton
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  border: "1px solid rgba(233, 236, 239, 0.20)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  textTransform: "none",
                  background: "rgba(255, 255, 255, 0.10)",
                  color: "#CED4DA",
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.10)",
                  },
                }}
                onClick={closeMetricDialog}
              >
                Cancel
              </SoftButton>
            )}

            {activeStep >= 1 && (
              <SoftButton
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  border: "1px solid rgba(233, 236, 239, 0.20)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  textTransform: "none",
                  background: "rgba(255, 255, 255, 0.10)",
                  color: "#CED4DA",
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.10)",
                  },
                }}
                onClick={handleBack}
              >
                Back
              </SoftButton>
            )}

            {activeStep <= 1 ? (
              <SoftButton
                color="light"
                sx={{
                  background: "#F9FAFB",
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "4px",
                  color: "#344054",
                }}
                onClick={handleNext}
              >
                Next
              </SoftButton>
            ) : (
              <SoftButton
                color="light"
                sx={{
                  background: "#F9FAFB",
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "4px",
                  color: "#344054",
                }}
                disabled={processing}
                onClick={() => handleSubmit(handleRegister)}
                type="submit"
              >
                {processing ? (
                  <CircularProgress color="inherit" size="1.5rem" />
                ) : (
                  "Save KPI"
                )}
              </SoftButton>
            )}
          </SoftBox>
        </form>
      </SoftBox>
  );
};

export default AnchorMetricDialog;
