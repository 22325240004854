import React from "react";

// MUI/SoftUI
import Tooltip from "@mui/material/Tooltip";

import SoftTypography from "../../../SoftTypography";
import SoftBox from "../../../SoftBox";
import SoftInput from "../../../SoftInput";

// Icons
import { ReactComponent as TooltipIcon } from "./TooltipIcon.svg";

const SetupFormInputField = ({
  label = "label",
  name = "name",
  placeholder = "Placeholder",
  description,
  tooltipText,
  isFakeTooltip = false,
  disabled = false,
  handleRegister,
  errors,
}) => {
  const fontStyles = {
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "14.5px",
    fontStyle: "normal",
    color: "#E9ECEF",
    lineHeight: "18px"
  };

  return (
    <SoftBox sx={{
      "& p": { ...fontStyles },
      margin: "16px 0 24px 0"
    }}>
      {/** Input label and Tooltip section */}
      <SoftBox sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <SoftTypography variant="body1">
          {label}
        </SoftTypography>
        {(tooltipText) &&
          <Tooltip title={tooltipText}>
            <button>
              <TooltipIcon />
            </button>
          </Tooltip>
        }

        {(isFakeTooltip && !tooltipText) &&
          <TooltipIcon />
        }
      </SoftBox>

      <SoftBox
        sx={{
          marginTop: (description) ? 0 : "16px",
          "& .MuiFormHelperText-root": { fontSize: "12px" }
        }}
      >
        {/** Label description section */}
        {(description) &&
          <SoftTypography
            variant="caption"
            sx={{
              ...fontStyles,
              fontSize: "11px",
              lineHeight: "14.32px",
              color: "#98A2B3",
              marginBottom: "8px",
            }}
          >
            {description}
          </SoftTypography>
        }

        <SoftInput
          name={name}
          placeholder={placeholder}
          autoComplete="off"
          disabled={disabled}
          {...(handleRegister && handleRegister(name))}
          error={!!errors?.[name]}
          helperText={errors?.[name]?.message}
          sx={{
            ...fontStyles,
            marginTop: "4px",
            fontSize: "12px",
            lineHeight: "15.62px",
            borderRadius: "6px",
            padding: "13px 16px",
            border: "0.99px solid #E9ECEF33",
            backgroundColor: "#3D3D3F !important",
            boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 4px rgba(8, 12, 19, 0.1)",
            color: "#EAECF0 !important",
            "& input": { fontSize: "12px" },
            "& .MuiInputBase-input::placeholder": {
              color: "#EAECF0 !important",
              fontSize: "12px",
              lineHeight: "15.62px"
            },
            "&.Mui-focused": {
              borderColor: (theme) => theme.palette.primary.main,
              boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 4px rgba(8, 12, 19, 0.1)",
            },
          }}
        />
      </SoftBox>
    </SoftBox>
  );
};

export default SetupFormInputField;
