import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useDispatch } from "react-redux";
import { addKpiRecord } from "../../redux/kpi/actions";
import { resizeImage } from "../../helpers/images";
import { formatNumber } from "../../helpers/formatter";

import {
  Box,
  Tooltip,
  Dialog,
  useTheme,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import { startCase, capitalize, debounce } from "lodash";
import toast from "react-hot-toast";
import { UseIsAdmin } from "../../hooks/auth";
import { KPI_IMAGE_PARAMS, KPI_TYPES } from "../../constants/kpi";
import Geolocation from "../../components/Geolocation";

const KpiModal = ({
  userId,
  kpi,
  index,
  month,
  userPoints,
  disabled = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [requiredInputs, setRequiredInputs] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [isItem] = useState(kpi.kind === "#");
  const [snapshotMode, setSnapshotMode] = useState(false); // Quick camera access for KPIs with only one image input
  const inputRef = useRef();
  const locationRef = useRef();
  const [hasLocation, setHasLocation] = useState(false);
  const theme = useTheme();
  const isAdmin = UseIsAdmin();
  const isAdminOnly = kpi?.isAdminAccess && !isAdmin;

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const inputFieldStyle = {
    borderRadius: "6px",
    border: "1px solid rgba(233, 236, 239, 0.10)",
    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
    color: "#ffffffb3 !important",
    "& .MuiInputBase-input::placeholder": {
      color: "white !important",
      opacity: "0.7",
    },
    "&.Mui-focused": {
      borderColor: primaryColor,
      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
    },
  };

  const setInput = (inputParam, value) => {
    if (inputParam.value && value) {
      const data = { ...metadata };
      data[inputParam.value] = value;
      setMetadata(data);
    }
  };

  const handleClose = () => {
    setProcessing(false);
    setOpen(false);
    setError("");
    props.onClose?.();
  };

  const getTooltipText = () => {
    if (isAdminOnly) {
      return "Players do not have input access for this KPI";
    } else {
      return kpi.isManual
        ? ""
        : "Manual input is allowed, but not recommended for integrated KPIs";
    }
  };

  const handleSubmit = debounce(async () => {
    const adding = toast.loading("Adding Kpi...");
    setProcessing(true);
    const formData = new FormData();
    for (let i = 0; i < requiredInputs.length; i += 1) {
      const item = requiredInputs[i];
      const key = kpi.requiredInputs[i].value;
      if (item.type !== "image" && item.type !== "picture") {
        formData.append(key, metadata[key]);
      } else {
        // eslint-disable-next-line no-await-in-loop
        const resizedImage = await resizeImage(metadata[key]);
        formData.append("image", resizedImage ?? metadata[key]);
        formData.append("imageKey", key);
      }
    }

    let amountValue = amount;

    if (isItem) {
      // "#" Item types always send amount=1
      amountValue = 1;
    } else if (kpi.kind === "%" && kpi?.metricType === KPI_TYPES[1]) {
      // Divide by 100 if "%" type anchor metric
      amountValue = parseFloat(amountValue) / 100;
    }

    formData.append("amount", amountValue);
    dispatch(addKpiRecord(kpi._id, userId, month, formData));

    toast.success("Score Added", { id: adding });
    props.onSave?.();
    handleClose();
  }, 500);

  useEffect(() => {
    let hasImage = false;
    if (kpi.requiredInputs && kpi.requiredInputs.length) {
      const inputs = [];
      let inputCount = 0;
      kpi.requiredInputs.forEach((input) => {
        const { value, type } = input;
        const isImage = KPI_IMAGE_PARAMS.includes(type);
        const isLocation = type === "location";
        const label = (value ?? type)
          .replaceAll("_", " ")
          .replaceAll(/\w+/g, capitalize);
        inputs.push({ label, value, type });
        hasImage = hasImage || isImage;
        inputCount += !isLocation ? 1 : 0; // Geolocation is hidden as does not count as an "input"
        setHasLocation((prevValue) => prevValue || isLocation);
      });
      // setSnapshotMode(hasImage && inputCount === 1);
      setRequiredInputs(inputs);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [kpi.requiredInputs]);

  const validateInputs = (e) => {
    e?.preventDefault();

    if (requiredInputs.length) {
      for (let k = 0; k < kpi.requiredInputs.length; k++) {
        const param = kpi.requiredInputs[k].value;
        const { type } = kpi.requiredInputs[k];
        if (type === 'checkbox' || type === 'notification_settings') continue;
        if (type === 'link' && (!metadata[param]?.includes('.') || 
            (metadata[param]?.startsWith('http') && !metadata[param]?.includes('://')) ||
            (metadata[param]?.startsWith('www') && !metadata[param]?.includes('.')))) {
          setError(`Please enter a valid URL`);
          return;
        }
        if (!metadata[param]) {
          setError(`Please complete KPI inputs. Missing: ${startCase(param)}`);
          return;
        }
      }
    }
    if (!isItem && !amount) {
      setError("Please input KPI amount.");
      return;
    }
    handleSubmit();
  };

  useEffect(() => {
    // Go straight to submission for one-image submissions
    if (snapshotMode && Object.keys(metadata).length) {
      validateInputs();
    }
  }, [snapshotMode, metadata]);

  return (
    <>
      <Tooltip title={getTooltipText()}>
        <SoftBox
          sx={{ cursor: "pointer" }}
          onClick={() => {
            // Disable showing the KPI modal pop-up
            if (disabled) return;
            if (isAdminOnly) return;

            setOpen(true);
            // Activate geolocation; this can only be done on user interaction
            if (hasLocation) {
              locationRef.current?.activate();
            }
            props.onClick?.();
            if (snapshotMode) {
              // Open camera on snapshot mode, but with minor delay to make sure that dialog components have already loaded
              setTimeout(() => inputRef?.current?.click(), 250);
            }
          }}
        >
          {props.children}
        </SoftBox>
      </Tooltip>
      <Dialog
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        sx={{ margin: "auto", width: { xs: "100vw", sm: "60vw" } }}
        PaperProps={{
          sx: {
            width: "100%",
            borderRadius: "8px",
            border: `3px solid ${primaryColor}`,
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(5px)",
          },
        }}
      >
        <SoftBox
          sx={{
            paddingX: 3,
            paddingTop: 3,
            paddingBottom: 0,
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox>
              <SoftTypography variant="h4" sx={{ color: "#FFF" }}>
                {kpi.title}
              </SoftTypography>
              <SoftTypography variant="body2" sx={{ color: "#FFF" }}>
                Enter {kpi.title} score
              </SoftTypography>
            </SoftBox>
            <SoftButton
              variant="outlined"
              color="white"
              onClick={handleClose}
              sx={{
                "& svg": { fontSize: "1.25rem !important" },
                borderRadius: "50%",
                minWidth: "max-content",
                minHeight: "max-content",
                padding: "0.25rem",
              }}
            >
              <CloseRoundedIcon />
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <Divider
          sx={{
            backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), ${primaryColor}, rgba(52, 71, 103, 0)) !important`,
            "&:not([size])": {
              height: "2px",
            },
          }}
        />
        <SoftBox
          sx={{
            paddingX: 3,
            paddingY: 0,
          }}
        >
          <SoftTypography variant="caption" sx={{ color: "#FFF" }}>
            Current score: {formatNumber(userPoints, kpi?.kind)}
          </SoftTypography>
        </SoftBox>

        <form>
          <SoftBox
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: 3,
              paddingY: 3,
              gap: 2,
            }}
          >
            {!isItem && (
              <SoftBox
                display="flex"
                flexDirection="column"
                gap="0.75rem"
                justifyContent="center"
              >
                <SoftInput
                  id="outlined-basic"
                  placeholder="Add amount"
                  type="number"
                  size="large"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  sx={inputFieldStyle}
                />
              </SoftBox>
            )}
            {requiredInputs &&
              requiredInputs.map((input, inputIdx) => {
                const { type, label, value: inputVal } = input;

                // Hide notification settings input
                if (type === "notification_settings") {
                  return (
                    <div key={`${label}`} style={{display: 'none'}}>
                      <SoftInput
                        placeholder={label}
                        id="outlined-basic"
                        type="text"
                        onChange={(e) =>
                          setInput(kpi.requiredInputs[inputIdx], e.target.value)
                        }
                        sx={inputFieldStyle}
                      />
                    </div>
                  );
                }

                // Image submission input
                if (KPI_IMAGE_PARAMS.includes(type)) {
                  return (
                    <>
                      <SoftBox className="flex flex-col items-center w-full gap-2">
                        <SoftButton
                          variant="outlined"
                          fullWidth
                          onClick={() => inputRef?.current.click()}
                        >
                          Upload {label}
                        </SoftButton>
                        {metadata?.[kpi.requiredInputs[inputIdx].value] && (
                          <SoftBox
                            borderRadius="lg"
                            className="flex w-3/5 justify-center overflow-hidden"
                          >
                            <img
                              src={URL.createObjectURL(
                                metadata[kpi.requiredInputs[inputIdx].value],
                              )}
                              alt={label}
                              style={{ objectFit: "cover" }}
                              width="100%"
                            />
                          </SoftBox>
                        )}
                      </SoftBox>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        ref={inputRef}
                        hidden
                        onChange={(e) => {
                          if (e.target.files.length === 0) {
                            return;
                          }
                          setInput(
                            kpi.requiredInputs[inputIdx],
                            e.target.files[0],
                          );
                          inputRef.current.value = null;
                        }}
                      />
                    </>
                  );
                } else if (type === "checkbox") {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ "& .MuiSvgIcon-root": { fontSize: "2rem" } }}
                          onChange={(e) =>
                            setInput(
                              kpi.requiredInputs[inputIdx],
                              e.target.checked,
                            )
                          }
                        />
                      }
                      label={`${label} (optional)`}
                      sx={{
                        margin: 0,
                        "& .MuiFormControlLabel-label": {
                          color: "#FFFFFF",
                          fontWeight: 400,
                          fontSize: "1rem",
                        },
                      }}
                    />
                  );
                } else if (type === "location") {
                  // Input is Geolocation, return null since this has different handling
                  return null;
                }
                // Input is standard text
                else {
                  const isMultiline = inputVal === "description";
                  return (
                    <div key={`${label}`}>
                      <SoftInput
                        placeholder={label}
                        id="outlined-basic"
                        type={type}
                        defaultValue={
                          type === "date" ? moment().format("yyyy-MM-DD") : ""
                        }
                        onChange={(e) =>
                          setInput(kpi.requiredInputs[inputIdx], e.target.value)
                        }
                        multiline={isMultiline}
                        maxRows={isMultiline ? 8 : 1}
                        sx={inputFieldStyle}
                      />
                    </div>
                  );
                }
              })}
          </SoftBox>

          <div style={{ padding: 4, marginBottom: 20 }}>
            {error && <p className="text-center text-red-500 mb-0">{error}</p>}
            <SoftButton
              variant="gradient"
              color="primary"
              onClick={validateInputs}
              disabled={processing}
              sx={{
                textTransform: "none",
                width: "max-content",
                padding: "14px 58px",
                fontSize: "1.1rem",
                display: "block",
                margin: "0 auto",
                marginTop: "30px",
                borderRadius: "8px",
                color: "#FFF",
                boxShadow:
                  "0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 6px -1px rgba(0, 0, 0, 0.12)",
              }}
            >
              Add {kpi.title ?? "KPI"}
            </SoftButton>
          </div>
          {/* <button
            type="submit"
            className="d-block mx-auto my-3 px-5"
            sx={{ border: "1px solid #FF0080" }}
          >
            Add
          </button> */}
        </form>
      </Dialog>
      {/* Geolocation is a hidden component; it's there but is not activated unless KPI requires location input */}
      <Geolocation
        ref={locationRef}
        onLocated={({ lat, lng, error: locationErr }) => {
          if (locationErr) {
            setError(locationErr);
          } else {
            setInput("location", `${lat},${lng}`);
          }
        }}
        hidden
      />
    </>
  );
};

export default KpiModal;
