import React, { useState } from "react";
import { CircularProgress, Dialog, Tooltip } from "@mui/material";
import toast from "react-hot-toast";
import {
  CloseRounded,
  QuestionMark,
  Psychology,
  SportsEsports,
  Person,
} from "@mui/icons-material";

import { ReactComponent as AdminPlayer } from "../../assets/icons/adminPlayer.svg";

import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import { PUT_USER } from "../../api/user";
import ERRORS from "../../constants/errorStatus";
import UpgradePopUp from "../../components/ChallengeArenaPopUp/UpgradePopUp";
import { useDispatch } from "react-redux";
import { getCompanyUsers } from "../../redux/company/actions";
import { fontSize, width } from "@mui/system";

const GradientCircle = () => (
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6538 7.5C15.6538 11.3317 12.2971 14.5 8.07692 14.5C3.85676 14.5 0.5 11.3317 0.5 7.5C0.5 3.66826 3.85676 0.5 8.07692 0.5C12.2971 0.5 15.6538 3.66826 15.6538 7.5Z" stroke="url(#paint0_linear_750_7465)"/>
    <ellipse cx="8.0769" cy="7.5" rx="5.9231" ry="5.50002" fill="url(#paint1_linear_750_7465)"/>
    <defs>
    <linearGradient id="paint0_linear_750_7465" x1="13.1171" y1="59.4771" x2="-3.98113" y2="58.3098" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7928CA"/>
    <stop offset="1" stopColor="#FF0080"/>
    </linearGradient>
    <linearGradient id="paint1_linear_750_7465" x1="11.7731" y1="45.6167" x2="-0.765693" y2="44.7606" gradientUnits="userSpaceOnUse">
    <stop stopColor="#7928CA"/>
    <stop offset="1" stopColor="#FF0080"/>
    </linearGradient>
    </defs>
  </svg>
);

const EmptyCircle = () => (
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6538 7.5C15.6538 11.3317 12.2971 14.5 8.07692 14.5C3.85676 14.5 0.5 11.3317 0.5 7.5C0.5 3.66826 3.85676 0.5 8.07692 0.5C12.2971 0.5 15.6538 3.66826 15.6538 7.5Z" stroke="#98A2B3"/>
  </svg>
);

const UpdatePlayerRole = ({ user, isLoggedInUser, isFirstAdmin }) => {
  const [updateRole, setUpdateRole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [selectedRole, setSelectedRole] = useState(user.isAdmin ? 'admin' : 'player');

  const openRemoveDialog = () => setUpdateRole(true);
  const closeUpdateRoleDialog = () => setUpdateRole(false);

  const openUpgradeDialog = () => setShowUpgrade(true);
  const closeUpgradeDialog = () => setShowUpgrade(false);

  const dispatch = useDispatch();

  const handleAdmin = async (id, admin) => {
    setLoading(true);
    const action = admin ? "Remov" : "Add";

    try {
      await PUT_USER(id, { isAdmin: !admin }, false);
      setLoading(true);
      closeUpdateRoleDialog();
      setTimeout(() => {
        dispatch(getCompanyUsers());
      }, 1000);

      toast.success(`Admin Successfully ${action}ed`);
    } catch (err) {
      setLoading(false);
      if (err?.response?.status === ERRORS.UPGRADE_REQUIRED) {
        openUpgradeDialog();
      }
      toast.error(err?.response?.data?.message || "Unable to update role");
    }
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  return (
    <>
      <SoftBox display="flex" alignItems="center">
        <SoftButton
          size="small"
          color="primary"
          disabled
          sx={{ 
            width: "60%", 
            borderRadius: "4px",
            border: "1px solid #EAECF0",
            background: "#FFFFFF !important",
            color: "#191D23",
            boxShadow: "none !important",
            opacity: "1 !important",
            "&:hover": {
              background: "#FFFFFF !important"
            },
            "& svg": {
              color: "#98A2B3 !important",
              marginLeft: "0.25rem"
            },
            "&:disabled": {
              color: "#191D23"
            }
          }}
        >
          {user.isAdmin ? (
            <>
              Admin &nbsp;<AdminPlayer fontSize="small" />
            </>
          ) : (
            <>
              Player &nbsp;<SportsEsports fontSize="small" />
            </>
          )}
        </SoftButton>
        {!isFirstAdmin && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={openRemoveDialog} style={{marginLeft: "0.25rem", cursor: "pointer"}}>
          <path d="M3.69333 13.0133C3.28667 13.0133 2.90667 12.8733 2.63333 12.6133C2.28667 12.2867 2.12 11.7933 2.18 11.26L2.42667 9.09999C2.47333 8.69333 2.72 8.15333 3.00667 7.85999L8.48 2.06666C9.84666 0.619992 11.2733 0.579992 12.72 1.94666C14.1667 3.31333 14.2067 4.73999 12.84 6.18666L7.36667 11.98C7.08667 12.28 6.56667 12.56 6.16 12.6267L4.01333 12.9933C3.9 13 3.8 13.0133 3.69333 13.0133ZM10.62 1.93999C10.1067 1.93999 9.66 2.25999 9.20667 2.73999L3.73333 8.53999C3.6 8.67999 3.44667 9.01333 3.42 9.20666L3.17333 11.3667C3.14667 11.5867 3.2 11.7667 3.32 11.88C3.44 11.9933 3.62 12.0333 3.84 12L5.98666 11.6333C6.18 11.6 6.5 11.4267 6.63333 11.2867L12.1067 5.49333C12.9333 4.61333 13.2333 3.79999 12.0267 2.66666C11.4933 2.15333 11.0333 1.93999 10.62 1.93999Z" fill="#98A2B3"/>
          <path d="M11.56 7.30001C11.5467 7.30001 11.5267 7.30001 11.5133 7.30001C9.43334 7.09334 7.76 5.51334 7.44 3.44668C7.4 3.17334 7.58667 2.92001 7.86 2.87334C8.13334 2.83334 8.38667 3.02001 8.43334 3.29334C8.68667 4.90668 9.99334 6.14668 11.62 6.30668C11.8933 6.33334 12.0933 6.58001 12.0667 6.85334C12.0333 7.10668 11.8133 7.30001 11.56 7.30001Z" fill="#98A2B3"/>
          <path d="M14 15.1667H2C1.72667 15.1667 1.5 14.94 1.5 14.6667C1.5 14.3933 1.72667 14.1667 2 14.1667H14C14.2733 14.1667 14.5 14.3933 14.5 14.6667C14.5 14.94 14.2733 15.1667 14 15.1667Z" fill="#98A2B3"/>
        </svg>
        )}
      </SoftBox>
      {updateRole && (
        <Dialog
          open={updateRole}
          onClose={closeUpdateRoleDialog}
          sx={{
            "& .MuiPaper-root": {
              padding: "1rem",
              borderRadius: "8px",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              width: "100%",
              maxWidth: "500px",
              borderWidth: "1px",
              borderColor: "#FFF",
            },
          }}
        >
          <SoftBox sx={{ padding: { mini: "1rem", md: "1.5rem" } }}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
              <SoftTypography
                component="h4"
                sx={{
                  fontWeight: 500,
                  color: "#FFF", 
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                Edit {user.firstName} {user.lastName} Role
              </SoftTypography>
              <SoftButton
                variant="outlined"
                color="white"
                onClick={closeUpdateRoleDialog}
                sx={{
                  "& svg": { fontSize: "1.25rem !important" },
                  borderRadius: "50%",
                  minWidth: "max-content",
                  minHeight: "max-content",
                  padding: "0.25rem",
                }}
              >
                <CloseRounded />
              </SoftButton>
            </SoftBox>
          <SoftBox paddingX={3} paddingBottom={3} paddingTop={1} sx={{mt: "4rem"}}>
            <SoftBox display="flex" flexDirection="column" gap="0.5rem">
                <SoftButton
                  onClick={() => handleRoleSelect('player')}
                  sx={{
                    width: "100%",
                    background:"rgba(255, 255, 255, 0.10)",
                    borderRadius: "8px",
                    padding: "0.875rem 1rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.05)",
                    "&:hover": {
                      background: "#3D3D3F", 
                    },
                    "&:focus:not(:hover)": {
                      background: "#3D3D3F", 
                    },
                  }}
                >
                  <SoftTypography variant="h6" sx={{ color: "#FFFFFF" }}>
                    Player Role
                  </SoftTypography>
                  {selectedRole === 'player' ? <GradientCircle /> : <EmptyCircle />}
                </SoftButton>
            </SoftBox>
            <SoftBox display="flex" flexDirection="column" gap="0.5rem" sx={{ mt: "1rem" }}>
                <SoftButton
                  onClick={() => handleRoleSelect('admin')}
                  sx={{
                    width: "100%",
                    background:"rgba(255, 255, 255, 0.10)",
                    borderRadius: "8px",
                    padding: "0.875rem 1rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.05)",
                    "&:hover": {
                      background: "#3D3D3F",
                    },
                    "&:focus:not(:hover)": {
                      background: "#3D3D3F", 
                    },
                  }}
                >
                  <SoftTypography variant="h6" sx={{ color: "#FFFFFF" }}>
                    Admin Role
                  </SoftTypography>
                  {selectedRole === 'admin' ? <GradientCircle /> : <EmptyCircle />}
                </SoftButton>
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop="2rem"
            >
              <SoftButton
                variant="contained"
                color="primary"
                onClick={() => handleAdmin(user.userId, user.isAdmin)}
                sx={{ 
                  textTransform: "none", 
                  borderRadius: "8px", 
                  padding: "0.875rem 1rem", 
                  background: "#FFFFFF", 
                  color: "#000000", 
                  fontWeight: "800", 
                  fontSize: "15px", 
                  width: "50%",
                  "&:hover": {
                    background: "#FFFFFF",
                  },
                  "&:focus:not(:hover)": {
                    background: "#FFFFFF",
                  },
                }}
              >
                Update
              </SoftButton>
            </SoftBox>
          </SoftBox>
          </SoftBox>
        </Dialog>
      )}
      {showUpgrade && (
        <UpgradePopUp
          showUpgrade={showUpgrade}
          closeUpgrade={closeUpgradeDialog}
        />
      )}
    </>
  );
};

export default UpdatePlayerRole;
