import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

// Components
import SetupDialog from "./layout/SetupDialog";
import popNotification from "../../Modals/popNotification";

// Forms
import SetupNameForm from "./forms/SetupNameForm";
import SetupProgressiveForm from "./forms/SetupProgressiveForm";
import SetupAnchorForm from "./forms/SetupAnchorForm";

// Constants
import { METRIC_OPTIONS, ANCHOR_BUTTON_STATE } from "./constants";
import { KPI_KINDS, KPI_TYPES } from "../../../constants/kpi";

// Validation
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import IntegratedDisplaySetupSchema from "../../../form-validations/IntegratedDisplaySetupSchema";

// Redux
import { updateWorkspaceKpi } from "../../../redux/kpi/actions";

const SetupSelectDialog = ({
  open = false,
  metric,
  handleReset
}) => {
  const [activeStepLocal, setActiveStep] = useState(1);
  const [metricType, setMetricType] = useState(METRIC_OPTIONS.PROGRESSIVE);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const steps = ["Step 1", "Step 2"];

  const validationOpt = {
    resolver: yupResolver(IntegratedDisplaySetupSchema),
    shouldUnregister: false,
    defaultValues: {
      ...metric,
      name: metric?.title,
      target: metric?.defaultTarget ?? 0,
      kind: metric?.kind,
      pointScale: metric?.defaultPointScale ?? 0,
      metricType: METRIC_OPTIONS.PROGRESSIVE,
      anchorSetup: ANCHOR_BUTTON_STATE.IDLE,
      isDraft: false
    }
  };

  const methods = useForm(validationOpt);

  useEffect(() => {
    if (open) {
      setActiveStep(1);
    }
  }, [open]);

  const handleMetricTypeSelect = (type) => {
    setMetricType(type);
  }

  // Builds anchor metric data
  const buildAnchorMetricData = (data) => {
    let target = data?.target;
    let unit = data?.unit;

    // Set the "%" target (a.k.a. "anchor") and unit value if anchorType is "percentage"
    // Divide them by 100 to send as "decimal percentage" to server (previous slider implementation)
    if (data?.kind === "%") {
      target = parseFloat(target) / 100;
      unit = parseFloat(data?.unit) / 100;
    }

    return {
      ...data,
      target: parseFloat(target),
      unit: parseFloat(unit),
      gap: true
    }
  }

  // Save the edited Integrated Display metric as an Integrated Anchor or Progressive metric
  // Note: Only offer to edit/change a Display Metric to an Anchor or Progressive only right after creating it
  const saveEditedDisplayIntegratedMetric = (payload) => {
    dispatch(updateWorkspaceKpi(payload));

    if (metric.isZapier && metric.integrationMeta) {
      navigate(`/zapier-setup?id=${metric.integrationMeta.id}`);
    } else {
      // Fake success notification that appears after a short delay (and not when data has been successfully processed)
      setTimeout(
        () =>
          popNotification({
            title: "Congratulations, Your Metric is Ready",
            text: `You've successfully activated ${metric.title.toUpperCase()} metric.`,
          }),
        1500,
      );

      handleReset?.();
    }
  }

  const handleOnNextStep = async () => {
    const currentPage = activeStepLocal - 1;
    const SETUP_NAME_FIELDS = ["name", "metricType", "isDraft"];
    const SETUP_PROGRESSIVE_FIELDS = ["target", "pointScale"];
    const SETUP_ANCHOR_FIELDS = ["target", "point", "unit", "anchorSetup"];

    const mType = methods.getValues("metricType");
    methods.clearErrors();

    const FORM_PAGES = [
      SETUP_NAME_FIELDS,
      (mType === METRIC_OPTIONS.PROGRESSIVE)
        ? SETUP_PROGRESSIVE_FIELDS
        : SETUP_ANCHOR_FIELDS
    ];

    const FINAL_FIELDS = [
      ...SETUP_NAME_FIELDS,
      ...FORM_PAGES[currentPage]
    ];

    // Validate input fields of the active stepper page
    const result = await methods.trigger(FORM_PAGES[currentPage], { shouldFocus: true })
    if (!result) return;

    if (activeStepLocal < steps.length) {
      // Increment step
      setActiveStep(prev => prev + 1);
    } else {
      // Build the Anchor or Progressive metric data
      const formData = {};

      FINAL_FIELDS.forEach(key => {
        formData[key] = methods.getValues(key)
      })

      const mergedData = { ...metric, ...formData }
      const kpiData = (mergedData.metricType === METRIC_OPTIONS.ANCHOR)
        ? buildAnchorMetricData(mergedData)
        : mergedData;

      // Submit final form data
      saveEditedDisplayIntegratedMetric(kpiData)
    }
  }

  const handleOnBackStep = () => {
    setActiveStep(prev => prev - 1);
    methods.clearErrors();
    methods.setValue("target", metric?.defaultTarget ?? 0);
  }

  const [isChecked, setIsChecked] = useState(false);


  return (
    <SetupDialog
      open={open}
      handleClose={handleReset}
      handleNextBtnPress={handleOnNextStep}
      handleBackBtnPress={handleOnBackStep}
      btnYesLabel="Next Step"
      btnNoLabel="Cancel"
      btnSubmitLabel="Save Setup"
      steps={steps}
      activeStep={activeStepLocal}
      isChecked={isChecked}
    >
      <FormProvider {...methods}>
        {(activeStepLocal === 1) &&
          <SetupNameForm
            activeMetric={metricType}
            handleMetricTypeSelect={handleMetricTypeSelect}
            isDraft={methods.getValues("isDraft")}
            setIsChecked={setIsChecked}
          />
        }

        {(activeStepLocal === 2 && metricType === METRIC_OPTIONS.PROGRESSIVE) &&
          <SetupProgressiveForm
            metric={metric}
            isChecked={isChecked}
          />
        }

        {(activeStepLocal === 2 && metricType === METRIC_OPTIONS.ANCHOR) &&
          <SetupAnchorForm
            metric={metric}
            isChecked={isChecked}
          />
        }
      </FormProvider>
    </SetupDialog>
  );
};

export default SetupSelectDialog;
