import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UseIsAdmin } from "../../hooks/auth";

import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import SoftButton from "../SoftButton";

// import GMB_LOGO from "../../img/main_logo.png";
// import CheckIcon from "@mui/icons-material/Check";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./styles/archivedModal.css";

const ArchivedModal = () => {
  const navigate = useNavigate();
  // const isAdmin = UseIsAdmin();

  return (
    <Dialog
      open={true}
      PaperProps={{
        className: "upgrade-modal-bg",
        sx: { minWidth: { mini: "220px", lg: "600px" } },
      }}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(5, 25, 35, 0.70)",
          },
        },
      }}
    >
      <Box className="flex w-full h-full justify-between">
        <Box
          className="flex flex-col"
          sx={{ width: { mini: "100%", lg: "100%" } }}
        >
          <Box className="flex items-center justify-center gap-2 mb-[3.5rem] text-center w-full">
            <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50.4677" cy="50.2983" r="50" fill="#F2F4F7"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M58.543 41.3867H41.7732V45.2703C41.7732 46.7813 42.9982 48.0064 44.5093 48.0064H55.8069C56.1662 48.0064 56.522 47.9357 56.854 47.7982C57.1859 47.6606 57.4876 47.4591 57.7416 47.205C57.9957 46.951 58.1973 46.6493 58.3348 46.3174C58.4723 45.9854 58.543 45.6296 58.543 45.2703V41.3867Z" fill="black" fillOpacity="0.16"/>
              <path d="M66.0454 41.3867L64.2801 61.8636C64.2801 64.2114 62.3913 66.1002 60.0435 66.1002H40.2727C37.9249 66.1002 36.0361 64.2114 36.0361 61.8636L34.2709 41.3867H66.0454ZM33.5648 34.3257H66.7514C67.5005 34.3257 68.2189 34.6233 68.7486 35.153C69.2783 35.6827 69.5759 36.4011 69.5759 37.1501V38.5623C69.5759 38.9332 69.5028 39.3005 69.3609 39.6432C69.2189 39.9859 69.0109 40.2972 68.7486 40.5595C68.4863 40.8218 68.175 41.0298 67.8323 41.1717C67.4896 41.3137 67.1224 41.3867 66.7514 41.3867H33.5648C32.8157 41.3867 32.0973 41.0892 31.5676 40.5595C31.0379 40.0298 30.7404 39.3114 30.7404 38.5623V37.1501C30.7404 36.7792 30.8134 36.4119 30.9553 36.0693C31.0973 35.7266 31.3053 35.4152 31.5676 35.153C32.0973 34.6233 32.8157 34.3257 33.5648 34.3257ZM57.2191 45.2703C57.2191 45.6448 57.0703 46.004 56.8055 46.2689C56.5406 46.5337 56.1814 46.6825 55.8069 46.6825H44.5093C44.1348 46.6825 43.7756 46.5337 43.5107 46.2689C43.2459 46.004 43.0971 45.6448 43.0971 45.2703V41.3867H57.2191V45.2703Z" stroke="#667085" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </Box>
          <Box className="flex flex-col gap-4 text-center">
            <p className="upgrade-title" style={{
              background: "linear-gradient(273.63deg, #7928CA 0%, #FF0080 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent", 
              backgroundClip: "text",
              textFillColor: "transparent"
            }}>Your Account Has Been Archived!</p>
            <p className="upgrade-text" style={{fontSize: "17px", color: "#667085", lineHeight: "27px"}}>
              Hi there! It looks like your account has been archived.<br/> 
              Don’t worry—this might just be a temporary thing.
            </p>
            <p className="upgrade-text" style={{fontSize: "17px", color: "#667085"}}>
            To get back in, please reach out to your admin, and they’ll help you sort things out.
            </p>
            <Box className="flex items-center justify-center text-center">
              <SoftButton
                variant="contained"
                onClick={() => navigate("/signout")}
                sx={{ bgcolor: "#591F99 !important", color: "#FFF", border: "1px solid", marginTop: "5rem", width: "50%" }}
                fullWidth
              >
                Sign Out
              </SoftButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ArchivedModal;
