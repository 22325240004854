import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Transition } from "react-transition-group";
import { formatNumber } from "../../../helpers/formatter";
import ReportsDoughnutChart from "../../../examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import SoftTypography from "../../SoftTypography";
import SoftProfile from "../../ProfilePictures/SoftProfile";
import HelperVideo from "../../HelperVideo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SoftBox from "../../SoftBox";

// Import from material ui
import RankIcon from "../../Icons/RankIcon";
import "./leaderboardStyles.css";

const duration = 250;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0.01 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const coloredBarClasses = [
  "coloredProgress1",
  "coloredProgress2",
  "coloredProgress3",
];

function ProgressBar({
  top,
  userId,
  imgsrc,
  userFirstName,
  userLastName,
  isOwnId,
  groupKpis,
  userKpis,
  position,
  prevPosition,
  showRankMove = false,
  isActive,
  isPointsPercent = false,
  onSelect,
  isYeti,
  isViewOnly = false,
  selectable = true,
  darkMode = false,
  height,
  fullColor = false,
}) {
  const [show, setShow] = useState(false);
  const points = Math.round(userKpis.total);
  const barProgress = userKpis.total
    ? Math.round((userKpis.total / top) * 100)
    : 0;

  // Chart Section
  const [kpiTitle, setKpiTitle] = useState([]);
  const [kpiValue, setKpiValue] = useState([]);
  const [kpiLabels, setKpiLabels] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const getKpiInfo = () => {
      const kpiNames = [];
      const kpiPoints = [];
      const kpiAmount = [];

      if (groupKpis?.length) {
        groupKpis.filter(kpi => !kpi.isDraft).map((kpi) => {
          const pts = Math.round(userKpis[kpi._id]?.pts || 0);
          const amt =
            userKpis[kpi._id] && userKpis[kpi._id].amt < 1 // Check if decimal / percentage value
              ? userKpis[kpi._id].amt
              : Math.round(userKpis[kpi._id]?.amt || 0);

          kpiNames.push(kpi.title);
          kpiPoints.push(pts);
          kpiAmount.push(formatNumber(amt, kpi.kind));
        });

        setKpiTitle(kpiNames);
        setKpiValue(kpiPoints);
        setKpiLabels(kpiAmount);
      } else {
        // Use dummy perfect values for the yeti
        setKpiTitle(["Yeti KPIs"]);
        setKpiValue([100]);
        setKpiLabels(["100%"]);
      }
    };

    getKpiInfo();
  }, [groupKpis, userKpis]);

  const handleSelect = () => {
    const shouldShow = !show;
    setShow(shouldShow);
    onSelect?.(shouldShow);
  };

  useEffect(() => {
    setShow((currentShow) => currentShow && isActive);
  }, [isActive]);

  return (
    <SoftBox sx={{ height: height ?? "auto" }}>
      <section
        className={`mb-[1%] py-[2%] px-[2%] rounded-full
          ${selectable && "hover:cursor-pointer"}
          ${isYeti ? "bg-gray-100" : "bg-transparent"}
        `}
        {...(selectable && { onClick: handleSelect })}
        // {...(position === 1 && { style: { border: "1px solid #D0D5DD" } })}
      >
        <Box className="flex items-center gap-[1%] w-full">
          <Box className="flex flex-col items-center min-w-[2rem]">
            <SoftTypography
              className="font-semibold"
              color={darkMode ? "white" : "black"}
              sx={{ fontSize: "1.75vh" }}
            >
              {position}
            </SoftTypography>
            {showRankMove && (
              <RankIcon
                difference={prevPosition - position}
                darkMode={darkMode}
              />
            )}
          </Box>
          <Box
            className="grow flex items-center gap-[1%] relative z-0"
            sx={{ padding: fullColor ? "1% 2% 1% 1%" : "0" }}
          >
            {/* Progress bar for full-color mode */}
            {fullColor && (
              <Box
                className={`
                w-full h-full absolute top-0 left-0 z-10
                ${
                  position <= coloredBarClasses.length
                    ? coloredBarClasses[position - 1]
                    : ""
                }
              `}
                // Set minimum bar progress
                sx={{ width: `${barProgress < 12 ? 12 : barProgress}%` }}
              />
            )}
            <SoftProfile
              size="lg"
              src={imgsrc}
              name={userFirstName}
              glow={isOwnId}
              sx={{ zIndex: 20 }}
              fullCover
            />
            <Box className="grow flex flex-col z-20">
              <Box className="flex items-center justify-between">
                <SoftTypography
                  variant={isMobile ? "h6" : "h5"}
                  color={darkMode ? "white" : "black"}
                  sx={{
                    fontSize: { sm: "1vh", md: "2vh" },
                    fontWeight: "700",
                    marginBottom: fullColor ? "-1vh" : 0,
                  }}
                >
                  {`${
                    userFirstName
                      ? userFirstName + " " + userLastName
                      : "Unregistered User"
                  }`}
                </SoftTypography>
                <SoftTypography
                  variant={isMobile ? "h5" : "h3"}
                  sx={{
                    textAlign: "center",
                    marginLeft: "1%",
                    lineHeight: 1.25,
                    fontWeight: "700",
                    fontSize: { sm: "1.75vh", md: "2.75vh" },
                  }}
                  color={darkMode ? "white" : "black"}
                >
                  {points} {isPointsPercent ? "%" : ""}
                </SoftTypography>
              </Box>
              <Box className="flex items-center gap-1 w-full">
                {/* Alternate progress bar - partial and not colored */}
                <Box
                  sx={{
                    height: "1vh",
                    borderRadius: "1vh",
                    overflow: "hidden",
                    flexGrow: 1,
                  }}
                >
                  {!fullColor && (
                    <Box
                      className="h-[1vh] transition-[width] duration-[600ms] ease-linear rounded-tr-[1vh] rounded-br-[1vh]"
                      sx={{
                        width: `${barProgress}%`,
                        backgroundColor: "primary.main",
                      }}
                    />
                  )}
                </Box>
                <SoftTypography
                  variant={isMobile ? "button" : "h6"}
                  color={darkMode ? "white" : "black"}
                  sx={{ lineHeight: 1, fontSize: "1vh" }}
                >
                  Points
                </SoftTypography>
              </Box>
            </Box>
          </Box>
        </Box>
      </section>

      <Transition in={show} timeout={duration} unmountOnExit>
        {(state) => (
          <SoftBox
            sx={{
              ...defaultStyle,
              ...transitionStyles[state],
              marginY: { mini: "0.5rem", md: "1rem" },
              paddingX: {
                mini: 0,
                xxs: "0.5rem",
                xs: "1rem",
              },
              paddingY: { mini: "0.25rem", xs: "0.5", md: "1rem" },
            }}
          >
            <ReportsDoughnutChart
              title={`${!userFirstName ? "User's" : userFirstName} KPIs`}
              count={{ number: points, text: "POINTS" }}
              chart={{
                labels: kpiTitle,
                labelItems: kpiLabels,
                datasets: {
                  label: "KPI",
                  backgroundColors: [
                    "primary",
                    "secondary",
                    "info",
                    "success",
                    "warning",
                  ],
                  data: kpiValue,
                },
              }}
              userId={userId}
              link={!isYeti && !isViewOnly}
              tooltip="See player for more info"
            />
          </SoftBox>
        )}
      </Transition>
    </SoftBox>
  );
}

export default ProgressBar;
