import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

// MUI/SoftUI
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import SoftBox from "../../../SoftBox";
import SoftTypography from "../../../SoftTypography";

// Components
import SetupFormInputField from "../layout/SetupFormInputField";
import SelectorButton from "../layout/SelectorButton";

// Icons
import { ReactComponent as TooltipIcon } from "../layout/TooltipIcon.svg";
import { METRIC_OPTIONS } from "../constants";

const SetupNameForm = ({
  activeMetric,
  handleMetricTypeSelect,
  setIsChecked
}) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const fontStyles = {
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#D0D5DD",
    lineHeight: "20.83px"
  };

  const handleSelectMetric = (metricType) => {
    handleMetricTypeSelect?.(metricType);
    setValue("metricType", metricType);
  };

  return (
    <SoftBox sx={{
      padding: "0 32px",
      "& p": { ...fontStyles }
    }}>
      {/** Metric name input field */}
      <SetupFormInputField
        label="Metric Name"
        placeholder="Metric name"
        name="name"
        handleRegister={register}
        errors={errors}
      />

      <SoftBox mb={2}>
        <SoftBox display="flex" alignItems="flex-start" gap={2}>
          <SoftBox>
            <Checkbox
              {...register("isDraft", {
                defaultValue: false,
                onChange: (e) => {
                  setValue("isDraft", e.target.checked);
                  setIsChecked(e.target.checked);
                }
              })}
              error={!!errors?.isDraft}
              helperText={errors?.isDraft?.message}
            />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" gap={2}>
            <SoftTypography
              variant="h4"
              sx={{
                marginTop: "0.25rem",
                color: "#CED4DA",
                fontSize: "15px",
                fontWeight: 500,
              }}
            >
              Save as a hidden/draft metrics (optional)
            </SoftTypography>
            <SoftTypography variant="p" sx={{ color: "#FFF", fontSize: "12px", fontWeight: 500, mb: 1, marginTop: "-0.5rem" }}>
              Hidden or draft metrics are not displayed on the dashboard but can be used as data source for composite metrics
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>

      {/** Choose Metric type selector section */}
      <SoftBox>
        {/** Title + Tooltip */}
        <SoftBox sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          marginBottom: "8px"
        }}>
          <SoftTypography
            variant="body1"
            sx={{
              ...fontStyles,
              fontWeight: "500 !important",
              fontSize: "14px !important",
              lineHeight: "20px !important",
              color: "#CED4DA !important",
            }}
          >
            Choose Metric Type
          </SoftTypography>
          <SoftBox>
            <TooltipIcon />
          </SoftBox>
        </SoftBox>

        {/** Subtitle */}
        <SoftBox sx={{ marginBottom: "10px" }}>
          <SoftTypography
            variant="caption"
            sx={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "10px",
              lineHeight: "12px",
              color: "#FFF"
            }}
          >
            What type of metrics do you want this KPI to be?
          </SoftTypography>
        </SoftBox>
      </SoftBox>

      {/** Choose Metric type selector - Progressive/Anchor selection */}
      <Grid container spacing={1}>
        <SelectorButton
          title="Progressive Metrics"
          subtitle="They aim to maintain a specific performance level over time."
          isSelected={activeMetric === METRIC_OPTIONS.PROGRESSIVE}
          handleClick={() => handleSelectMetric(METRIC_OPTIONS.PROGRESSIVE)}
        />

        <SelectorButton
          title="Anchor Metrics"
          subtitle="They aim to maintain a specific performance level over time, "
          isSelected={activeMetric === METRIC_OPTIONS.ANCHOR}
          handleClick={() => handleSelectMetric(METRIC_OPTIONS.ANCHOR)}
        />
      </Grid>

      {/** Footer text */}
      <SoftBox
        sx={{
          width: "80%",
          margin: "28px 0"
        }}
      >
        <SoftTypography
          variant="body1"
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 500,
            fontSize: "12px !important",
            lineHeight: "15.62px !important",
            color: "#D0D5DD",
          }}
        >
          The progressive metrics and the anchor metrics will enable you to allocate and setup a target for your players.
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

export default SetupNameForm;
