import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { UseUserId, UseUserDetails } from "../../hooks/auth";
import { UseUsersDictionary, UseCompany } from "../../hooks/company";
import { UseTopPlayers, UseGroupKpis } from "../../hooks/kpi";
import { FETCH_JOB } from "../../api/jobs";

import SoftBox from "../../components/SoftBox";
import PageHeader from "../../components/PageHeader";
import Avatar from "../../components/Avatar";
import ItemBox from "../../components/YetiKpiBox/ItemBox";
import ScoreData from "./ScoreData";
import Backdrop from "@mui/material/Backdrop";
import FeedItem from "../Feed/FeedItem";
import LeaderboardBg from "../../img/ca-bg.png";
import KpiBox from "../../components/Scorecard/KpiBox";

// Imported Styles
import "./scorecardStyles.css";
import { GET_IMAGE } from "../../helpers/images";
import SoftTypography from "../../components/SoftTypography";

const Scorecard = () => {
  const [kpis, setKpis] = useState([]);
  const [player, setPlayer] = useState(null);
  const [refItem, setRefItem] = useState(null);
  const [filteredKpiId, setFilteredKpiId] = useState(null);

  // Redux company and user states
  const authUserId = UseUserId();
  const { logo, industry } = UseCompany();
  const userScores = UseTopPlayers();
  const usersDict = UseUsersDictionary();
  const groupKpis = UseGroupKpis();

  // URL parameters
  const [searchParams] = useSearchParams();
  const kpiId = searchParams.get("kpi");
  const userId = searchParams.get("user") ?? authUserId;
  const navigate = useNavigate();
  const { isAdmin } = UseUserDetails();

  useEffect(() => {
    if (userId && usersDict) {
      setPlayer({ ...usersDict[userId], userId });
    }
  }, [userId, usersDict]);

  useEffect(() => {
    if (player && groupKpis?.length > 0) {
      const playerScores = userScores?.find(
        (item) => item.userId === player.userId,
      );
      const kpiSummary = groupKpis?.filter(kpi => !kpi.isDraft).map((kpiItem) => ({
        ...kpiItem,
        amount: playerScores?.scores[kpiItem._id]?.amt ?? 0,
        points: playerScores?.scores[kpiItem._id]?.pts ?? 0,
        target: kpiItem.target,
      }));
      setKpis(kpiSummary);
      
      // Set the initial filteredKpiId if it hasn't been set yet
      if (filteredKpiId === null) {
        setFilteredKpiId(kpiId || null);
      }
    }
  }, [player, groupKpis, userScores, kpiId, filteredKpiId]);

  const selectKpi = (kpi) => {
    setFilteredKpiId(kpi);
  };

  const showItem = ({ jobId, imageKey, notes }) => {
    if (jobId) {
      FETCH_JOB(jobId).then(({ data }) => {
        setRefItem({ job: data });
      });
    } else if (imageKey) {
      setRefItem({ imageKey, notes });
    }
  };

  return (
    <SoftBox
      sx={{
        height: "100%",
        marginTop: 0,
        backgroundImage: `url(${LeaderboardBg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: { mini: "cover" },
        width: "100%",
        marginX: "auto",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SoftBox
        sx={{
          minHeight: "480px",
          height: { mini: "65vh", sm: "80vh", lg: "90vh" },
          maxWidth: "100vw",
          marginX: { mini: 0, sm: "1.5rem", lg: "2rem" },
          marginBottom: { mini: 0, lg: "1rem" },
          borderRadius: (t) => ({ mini: 0, lg: t.borders.borderRadius.lg }),
          position: "relative",
          overflow: "hidden",
          marginTop: "4rem",
        }}
      >
        <SoftBox
          sx={{
            position: "absolute",
            left: { mini: 0, xxxl: "2rem" },
            display: { mini: "none", xxl: "flex" },
            height: "100%",
            minWidth: { mini: "12rem", sm: "14rem", xxl: "25%", xxxl: "320px" },
            flexDirection: "column",
            justifyContent: "center",
            gap: { mini: "4px", md: "6.5px" },
            zIndex: 2,
            cursor: "pointer",
          }}
        >
          {kpis.length > 0
            ? kpis.map((kpi) => (
                <KpiBox
                  kpiId={filteredKpiId}
                  key={kpi._id}
                  kpi={kpi}
                  amount={kpi.amount}
                  points={kpi.points}
                  onClick={() => selectKpi(kpi._id)}
                />
              ))
            : null}
        </SoftBox>

        {/* Avatar container */}
        <SoftBox
          sx={{
            width: "85%",
            height: "80%",
            position: "absolute",
            left: {
              mini: "-12%",
              lg: isAdmin ? "-5%" : "3%",
              xxl: isAdmin ? "-4%" : "4%",
              xxxl: isAdmin ? "-3%" : "5%",
            },
            top: "10%",
          }}
        >
          {player && (
            <Avatar
              url={player?.avatar}
              logo={logo}
              fixed={false}
              industry={industry}
              isOwn={true}
              noControls={true}
            />
          )}
        </SoftBox>

        <SoftBox
          sx={{
            borderRadius: "16px",
            height: "100%",
            width: {
              mini: "max-content",
              xxs: "50%",
              lg: "max-content",
              xxl: "50%",
            },
            position: "absolute",
            right: { mini: "1rem", xs: "5%", md: "10%", lg: "20%", xxl: 0 },
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 0,
            marginY: "auto",
          }}
        >
          {/* Scorecard KPI tiles */}
          <SoftBox
            sx={{
              display: { mini: "flex", xxl: "none" },
              height: "80%",
              minWidth: { mini: "10rem", sm: "16rem" },
              flexDirection: "column",
              justifyContent: "center",
              gap: { mini: "4px", md: "6.5px" },
              zIndex: 2,
              cursor: "pointer",
            }}
          >
            {kpis.length > 0
              ? kpis.map((kpi) => (
                  <KpiBox
                    key={kpi._id}
                    kpi={kpi}
                    amount={kpi.amount}
                    points={kpi.points}
                    onClick={() => selectKpi(kpi._id)}
                  />
                ))
              : null}
          </SoftBox>

          {/* Scores table for large screens */}
          <SoftBox
            sx={{
              borderRadius: "16px",
              border: "1px solid rgba(255, 255, 255, 0.10)",
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), rgba(28, 132, 207, 0.30)",
              height: "100%",
              display: { mini: "none", xxl: "flex" },
              backdropFilter: "blur(16px)",
              minWidth: "40vw",
              minHeight: "50vh",
            }}
          >
            <ScoreData
              light={false}
              player={player}
              kpiId={filteredKpiId}
              isOwn={player?.userId === authUserId}
              viewItem={showItem}
              isSmallScreen={false}
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>

      {/* Scores table for small screens */}
      <SoftBox
        shadow="lg"
        borderRadius="xl"
        sx={{
          display: { mini: "block", xxl: "none" },
          marginTop: { mini: "0.5rem", sm: "1rem" },
          marginX: { mini: "0.5rem", sm: "1rem" },
          color: "white",
          maxWidth: "100vw",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), rgba(28, 132, 207, 0.30)",
          backdropFilter: "blur(17px)",
          border: "1px solid rgba(255, 255, 255, 0.10)",
          marginBottom: { mini: "6rem", md: "1rem" },
        }}
      >
        <ScoreData
          player={player}
          kpiId={filteredKpiId}
          isOwn={player?.userId === authUserId}
          viewItem={showItem}
          isSmallScreen={true}
        />
      </SoftBox>

      {refItem && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={refItem !== null}
          onClick={() => setRefItem(null)}
        >
          <SoftBox
            sx={{
              width: { xs: "90vw", sm: "80vw", xl: "70vw" },
              maxHeight: { xs: "95vh", lg: "85vh" },
            }}
            className="flex justify-center"
          >
            {refItem.job && <FeedItem job={refItem.job} isModal />}
            {refItem.imageKey && (
              <SoftBox
                borderRadius="lg"
                shadow="lg"
                bgColor="white"
                className="flex flex-col w-[90%] p-8 gap-4 items-center"
              >
                <SoftBox borderRadius="lg" className="overflow-hidden">
                  <img
                    src={GET_IMAGE(refItem.imageKey)}
                    height="100%"
                    alt="Reference Item"
                    style={{ objectFit: "cover", objectPosition: "center" }}
                  />
                </SoftBox>
                <SoftTypography variant="body2">{refItem.notes}</SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </Backdrop>
      )}
    </SoftBox>
  );
};

export default Scorecard;
