import React, { useEffect, useState, useMemo } from "react";
import {
  FormControlLabel,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Dialog,
  Divider,
  Pagination,
} from "@mui/material";
import { useTheme } from "@material-ui/core";
import Switch from "@mui/material/Switch";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// Imported Images and Icons
import AddUser from "./AddUser";
import {
  UseCompanyWorkspaces,
  UseUsersFullData,
} from "../../hooks/company";
import { useDispatch } from "react-redux";
import { getCompanyUsers } from "../../redux/company/actions";
import User from "./User";
import { debounce } from "lodash";
import Mark from "mark.js";
import { ARCHIVE_COLLEAGUE } from "../../api/user";
import SoftInput from "../../components/SoftInput";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import SoftSelect from "../../components/SoftSelect";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ReactComponent as SearchSvg } from "../../img/search.svg";
import SoftTypography from "../../components/SoftTypography";
import PlayersTable from "./PlayersTable";

// Soft UI Dashboard PRO React base styles
import colors from "../../assets/theme/base/colors";
import typography from "../../assets/theme/base/typography";
import borders from "../../assets/theme/base/borders";
import UserTable from "./UserTable";
import AddPlayerBillingUpdate from "./AddPlayerBillingUpdate";

import { UseUserDetails } from "../../hooks/auth";

const LIMIT = 10;

const getGroupName = (groupId, groups) => {
  const group = groups.find(({ _id }) => _id === groupId);
  return group?.name || "";
};

const searchUser = (searchKey, data, groups) => {
  const key = searchKey.toLowerCase();
  const filteredData = data.filter((user) => {
    return (
      user?.firstName?.toLowerCase().includes(key) ||
      user?.lastName?.toLowerCase().includes(key) ||
      user?.email?.toString().toLowerCase().includes(key) ||
      getGroupName(user?.groupId, groups).toLowerCase().includes(key) ||
      (user?.isAdmin ? "admin" : "").includes(key)
    );
  });
  return filteredData;
};

const UmTable = () => {
  const allWorkspaces = UseCompanyWorkspaces(true);
  const workspaces = useMemo(
    () => [...allWorkspaces].sort((a, b) => a.name.localeCompare(b.name)),
    [allWorkspaces],
  );
  const allUsers = UseUsersFullData();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [showAll, setShowAll] = useState(0);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [currentWorkspace, setCurrentWorkspace] = useState('All');
  const [activePlayers, setActivePlayers] = useState(null);
  const [inactivePlayers, setInactivePlayers] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const roles = [
    { value: "player", label: "Player" },
    { value: "admin", label: "Admin" },
  ];

  const columns = [
    { name: "Players Name" },
    { name: "Workspace assigned" },
    { name: "Status" },
    { name: "Players Role" },
    { name: "Actions" },
  ];

  const userDetails = UseUserDetails();

  useEffect(() => {
    if (workspaces?.length) {
      const options = workspaces.map(workspace => ({
        value: workspace._id,
        label: workspace.name
      }));
      setWorkspaceOptions(options);
    }
  }, [workspaces]);

  // eslint-disable-next-line no-shadow
  const debounceFilter = debounce((searchKey, users) => {
    const filtered = searchUser(searchKey, users, workspaces);
    setTotalPages(Math.ceil(filtered.length / LIMIT));
    setFilteredUsers(filtered.slice((page - 1) * LIMIT, page * LIMIT));
    const context = document.querySelector("#search-table-body");
    const markInstance = new Mark(context);
    markInstance.unmark({
      done: () => {
        markInstance.mark(searchKey);
      },
    });
  }, 100);

  const handlePageChange = (event, value) => {
    setPage(value);
    const start = (value - 1) * LIMIT;
    const end = value * LIMIT;
    setFilteredUsers(workspaceUsers.slice(start, end));
  };

  // filter users by current workspace or if ungrouped
  useEffect(() => {
    let activeUsers = allUsers.filter(user => user.groupId && !user.isArchived && user.logins > 0);
    const inactiveUsers = allUsers.filter(user => !user.groupId || (user.groupId && user.isArchived) || user.logins === 0);

    // Filter by currentWorkspace if not "All"
    if (currentWorkspace !== 'All') {
      activeUsers = activeUsers.filter(user => user.groupId === currentWorkspace);
    }

    activeUsers.sort((a, b) => {
      if (!b.groupId) return -1;
      return a.firstName.localeCompare(b.firstName);
    });

    setActivePlayers(activeUsers.length);
    setInactivePlayers(inactiveUsers.length);
    setWorkspaceUsers(activeUsers);
    setTotalPages(Math.ceil(activeUsers.length / LIMIT));
    setFilteredUsers(activeUsers.slice(0, LIMIT));
    setPage(1);
  }, [allUsers, currentWorkspace]);

  useEffect(() => {
    if (showAll) {
      const inactiveUsers = allUsers.filter(user => !user.groupId || (user.groupId && user.isArchived) || user.logins === 0);
      setTotalPages(Math.ceil(inactiveUsers.length / LIMIT));
      setFilteredUsers(inactiveUsers.slice(0, LIMIT));
      setPage(1);
    } else {
      setTotalPages(Math.ceil(workspaceUsers.length / LIMIT));
      setFilteredUsers(workspaceUsers.slice(0, LIMIT));
      setPage(1);
    }
  }, [workspaceUsers, showAll, allUsers]);

  const archiveUser = async (id, isArchived) => {
    // flag as archived for immediate action feedback
    const updatedList = [...workspaceUsers];
    const idx = updatedList.findIndex((user) => user.userId === id);
    if (idx > 0) {
      updatedList[idx].isArchived = isArchived;
      setWorkspaceUsers(updatedList);
    }
    await ARCHIVE_COLLEAGUE(id, isArchived);
  };

  return (
    <SoftBox>
      <Grid container sx={{ marginBottom: "1rem" }}>
        <Grid item mini={8}>
          <SoftTypography
            sx={{ color: "#000", fontWeight: 500, fontSize: "20px" }}
          >
            Players
          </SoftTypography>
          <SoftTypography
            sx={{ color: "#667085", fontWeight: 500, fontSize: "16px" }}
          >
            Manage all players in the workspace, including active and inactive members.
          </SoftTypography>
        </Grid>
        <Grid item mini={4}>
          <SoftBox sx={{ display: "flex", alignItems: "center", gap: 2, width: "100%" }}>
            {workspaceOptions?.length && (
              <SoftBox sx={{ flex: 0.4 }}>
                <SoftSelect
                  size="large"
                  options={[
                    { value: "All", label: "All Workspaces" },
                    ...workspaceOptions
                  ]}
                  defaultValue={{ value: "All", label: "All Workspaces" }}
                  onChange={(option) => setCurrentWorkspace(option.value)}
                  styles={{ 
                    width: "100%", 
                    borderRadius: "4px",
                    background: "#FFFFFF !important",
                    border: "1px solid #FFFFFF !important",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none"
                    }
                  }}
                />
              </SoftBox>
            )}
            <SoftBox sx={{ flex: 0.8 }}>
              <Tabs
                value={showAll}
                onChange={(e, value) => setShowAll(value)}
                sx={{
                  borderRadius: "4px",
                  padding: "10px",
                  width: "100%",
                  "& .MuiTabs-indicator": {
                    borderRadius: "4px",
                  },
                  "& .MuiTab-root": {
                    padding: "10px",
                    flex: 1
                  },
                }}
              >
                <Tab
                  label={`Active Players (${activePlayers})`}
                  sx={{
                    fontSize: "0.75rem",
                    ".css-3k008v-MuiButtonBase-root-MuiTab-root": {
                      borderRadius: "4px !important", 
                      background: "inherit",
                      padding: "10px 15px",
                    },
                  }}
                />
                <Tab
                  label={`Inactive Players (${inactivePlayers})`}
                  sx={{
                    fontSize: "0.75rem",
                    ".css-3k008v-MuiButtonBase-root-MuiTab-root": {
                      borderRadius: "4px !important",
                      background: "inherit", 
                      padding: "10px 15px",
                    },
                  }}
                />
              </Tabs>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
      <SoftBox
        sx={{
          background: "#FFF",
          borderRadius: "12px",
          padding: "1rem 1rem 1rem 1.5rem",
        }}
      >
        <Grid container sx={{ marginY: "1rem" }}>
          <Grid item mini={4}>
            <SoftInput
              className="bg-light rounded-lg"
              type="text"
              placeholder="Search Players"
              onChange={(e) => {
                debounceFilter(e.target.value, allUsers);
                setSearch(e.target.value);
              }}
              sx={{
                background: "linear-gradient(0deg, #f8f9fa, #f8f9fa), linear-gradient(0deg, #f8f9fa, #f8f9fa)",
                border: "1px solid #F2F4F7",
                borderRadius: '4px',
              }}
              icon={{
                component: <SearchSvg />,
                direction: "left",
              }}
            />
          </Grid>

          <Grid item mini={8}>
            <SoftBox
              sx={{
                display: "flex",
                justifyContent: { mini: "start", md: "end" },
                alignItems: "center",
                gap: { mini: "0.5rem", md: "1rem" },
              }}
            >
              <AddUser />
            </SoftBox>
          </Grid>
        </Grid>
        <hr className="my-3 divide-slate-300" />
        <Table>
          <SoftBox component="thead">
            <TableRow>
              {columns.map((column, i) => (
                <TableCell style={{ width: "20%" }}>{column.name}</TableCell>
              ))}
            </TableRow>
          </SoftBox>
          <TableBody>
            {filteredUsers.map((user) => {
              // Find first admin by checking isAdmin and earliest createdAt date
              const firstAdmin = filteredUsers
                .filter(u => u.isAdmin)
                .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))[0];
              
              // Check if current user is the first admin
              const isFirstAdmin = firstAdmin?.userId === user.userId;

              return (
                <User
                  key={user.userId}
                  user={user}
                  workspaces={workspaces}
                  archiveUser={archiveUser}
                  isFirstAdmin={isFirstAdmin}
                />
              );
            })}
          </TableBody>
        </Table>
        {totalPages > 1 && (
          <SoftBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
            <SoftBox sx={{ flex: 1 }} />
            <Pagination 
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              sx={{
                '& .MuiPaginationItem-root': {
                  borderRadius: '4px',
                },
                '& .MuiPaginationItem-page:hover': {
                  borderRadius: '4px',
                },
                '& .Mui-selected': {
                  borderRadius: '4px',
                }
              }}
            />
            <SoftBox sx={{ flex: 1, textAlign: 'right' }}>
              <SoftTypography variant="body2" color="text" sx={{ color: "#000000" }}>
                Showing {filteredUsers.length} of {workspaceUsers.length} Results
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
    </SoftBox>
  );
};

export default UmTable;
